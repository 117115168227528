import React, { useState, useEffect } from 'react';
import UpperNavbar from '../../Assets/NavBar/UpperNavbar';
import logo from '../NavigationBar/logo192.png';

const menuItems = [
    { id: 'home', label: 'Home', route: '/home' },
    {
        id: 'wallpapers',
        label: 'Wallpapers',
        route: '/wallpapers',
        // submenu: [
        //     { label: 'Premium Wallpapers', route: '/premium_wallpapers' },
        //     { label: 'Wallpaper Rolls', route: '/wallpaper_rolls' }
        // ]
    },
    {
        id: 'printings',
        label: 'Printings',
        route: '/printings',
        submenu: [
            { label: 'Photo Printing', route: '/photo_printings' },
            { label: 'Customised Printing', route: '/customised_printings' }
        ]
    },
    {
        id: 'canvas-printing',
        label: 'Canvas Printing',
        route: '/canvas-printing',
        submenu: [
            { label: 'Canvas Printing', route: '/canvas_printing' },
            { label: 'Customised Canvas Printing', route: '/customised_canvas-printing' }
        ]
    },
    {
        id: 'about-us',
        label: 'About Us',
        route: '/about-us',
        submenu: [
            { label: 'About Us', route: '/about_us' },
            { label: 'Contact Us', route: '/contact_us' }
        ]
    }
];

function NavigationBar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
            setIsLoggedIn(true);
            setUserEmail(userData.email);
        } else {
            setIsLoggedIn(false);
            setUserEmail('');
        }
    }, []);

    const cartCount = 1; // Replace with your cart count logic
    const favoriteCount = 5; // Replace with your favorite count logic

    return (
        <UpperNavbar
            logo={logo}
            menuItems={menuItems}
            isLoggedIn={isLoggedIn}
            cartCount={cartCount}
            favoriteCount={favoriteCount}
            userEmail={userEmail} // Pass userEmail to UpperNavbar
        />
    );
}

export default NavigationBar;
