import React from 'react'
import PhotoFrame from '../Assets/Customization/PhotoFrame/PhotoFrame'

const CustomisedPrintings = () => {
  return (
    <PhotoFrame/>
  )
}

export default CustomisedPrintings
