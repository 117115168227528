import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../GlobalStyling/GlobalStyling.css';
import './ProductListHScroll.css';
import Button4 from '../Buttons/Button4';

const ProductListHScroll = () => {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const scrollRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://api.artwalle.pattanaikdatapoint.com/productlist');
                setProducts(response.data);
            } catch (error) {
                setError('Error fetching products');
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, []);

    // const formatImagePath = (path) => {
    //     if (!path) return '/ProductImages/default-image.jpeg'; // Fallback image
    //     const filename = path.split('\\').pop(); // Extract the filename from the absolute path
    //     return `/ProductImages/${filename}`;
    // };


    const formatImagePath = (path) => {
        if (!path) return '/ProductImages/default-image.jpeg'; // Fallback image
        const filename = path.split('\\').pop(); // Extract the filename from the absolute path
        return `https://artwalle.com/ProductImages/${filename}`;
    };

    const handleProductClick = (id, category) => {
        switch (category) {
            case "Printings":
                navigate(`/CustomizedPrintings/${id}`);
                break;
            case "Canvas":
                navigate(`/CustomizedCanvas/${id}`);
                break;
            default:
                navigate(`/ProductList/${id}`);
        }
    };

    const scrollLeft = () => {
        if (scrollRef.current) {
            const cardWidth = scrollRef.current.children[0].clientWidth;
            scrollRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            const cardWidth = scrollRef.current.children[0].clientWidth;
            scrollRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' });
        }
    };

    return (
        <div className='product-list-hscroll'>
            <div className='collection-header'>
                <h1>Exclusive Monsoon Collection</h1>
                <p>Explore a blend of tranquil and refreshing aura...</p>
                <div className="scroll-button">
                    <Button4 onClick={scrollLeft}>{'<'}</Button4>
                    <Button4 onClick={scrollRight}>{'>'}</Button4>
                </div>
            </div>
            <div className='scroll-container'>
                <div className='product-scroll' ref={scrollRef}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        products.map((product) => (
                            <div key={product.id} className='product-scroll-product-card'>
                                <img
                                    src={formatImagePath(product.product_img1)}
                                    alt=""
                                    onClick={() => handleProductClick(product.id, product.category)}
                                />
                                <div className='product-details'>
                                    <div className='product-title'>{product.product_name}</div>
                                    <div className='product-price'>&#8377; {product.product_price}</div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductListHScroll;
