import React from 'react'
import ProductDetails from '../Assets/ProductDetails/ProductDetailsPremiunWallpapers'

const Products = {
  name: "Product Name 1",
  rating: 3.5,
  reviewCount: 5,
  originalPrice: 999,
  discountedPrice: 499,
  deliveryDate: "17 Dec",
  location: "751030",
  materials: ["Non-Woven", "Paper", "Wood"],
  defaultMaterial: "Non-Woven",
  frameTypes: ["No Frame", "Framed"],
  defaultFrameType: "No Frame",
  specifications: [
    "Material: High-quality vinyl/paper",
    "Size Options: Various dimensions available, customizable to fit any wall size",
    "Design Options: Extensive range of designs including floral, geometric, abstract, landscapes, and customizable options",
    "Durability: Scratch-resistant, washable, and fade-resistant for long-lasting vibrancy",
    "Installation: Easy to install with peel-and-stick or paste-the-wall application methods",
    "Environmentally Friendly: Eco-conscious materials and printing processes, free from harmful chemicals",
    "Maintenance: Low maintenance, wipeable surface for easy cleaning",
    "Usage: Suitable for residential use in living rooms, bedrooms, dining areas, and more",
    "Customization: Option for custom designs, colors, and sizes to suit individual preferences",
    "Warranty: Product warranty ensuring quality and customer satisfaction"
  ]
};

const WallpaperRolls = () => {
  return (
    <ProductDetails product={Products}/>
  )
}

export default WallpaperRolls
