import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../../Functions/firebaseConfig';
import { signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import './Register.css'; // Import the CSS file
import googleLogo from './Google_GoogleLogo.png'
import Button2 from '../Buttons/Button2';
import PopupErrorNotification from '../PopUp/PopupErrorNotification';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [alreadyRegistered, setAlreadyRegistered] = useState('');
  const [popupMessage, setPopupMessage] = useState(null); // State for popup message

  const navigate = useNavigate(); // Use the useNavigate hook

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobile = (mobile) => {
    const re = /^\d{10}$/;
    return re.test(String(mobile));
  };

  const handleRegister = () => {
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (password.length < 6) {
      // setPasswordError('Password cannot be less than 6 characters.');
      setPopupMessage('Password cannot be less than 6 characters.');

      valid = false;
    } else {
      setPasswordError('');
    }

    if (!validateMobile(mobile)) {
      setMobileError('Please enter a valid mobile number.');
      setPopupMessage('Please Enter a Valid 10 Digit Mobile Number');

      valid = false;
    } else {
      setMobileError('');
    }

    if (valid) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          console.log('Registered:', userCredential);

          const userData = {
            email: userCredential.user.email,
            uid: userCredential.user.uid,
            mobile: mobile,
            firstName: firstName,
            lastName: lastName,
            registrationDate: new Date().toISOString()
          };

          // Store user data in local storage
          localStorage.setItem('userData', JSON.stringify(userData));

          // Store user data in MySQL
          try {
            await axios.post('https://api.artwalle.pattanaikdatapoint.com/register', userData);
            console.log('User data stored in MySQL');
            navigate('/'); // Navigate to home page after successful registration
            window.location.reload(); // Reload the page after navigating
          } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data === 'User is already registered.') {
              setAlreadyRegistered('User is already registered. Please log in.');
              setPopupMessage('User is already registered. Please log in.');
            } else {
              console.error('Error storing user data:', error);
              setPopupMessage('Failed to register. Please try again later.'); // Set error message
            }
          }
        })
        .catch((error) => {
          if (error.code === 'auth/email-already-in-use') {
            // setEmailError('This email is already in use.');
            setPopupMessage('User is already registered. Please log in.');
            setAlreadyRegistered(true);
          } else {
            console.error('Error during registration:', error);
            setPopupMessage('Failed to register. Please try again later.'); // Set error message
          }
        });
    }
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        console.log('Google sign-in successful:', result);

        const userData = {
          email: result.user.email,
          uid: result.user.uid,
          mobile: result.user.phoneNumber || '',
          firstName: result.user.displayName.split(' ')[0],
          lastName: result.user.displayName.split(' ')[1],
          registrationDate: new Date().toISOString()
        };

        // Store user data in local storage
        localStorage.setItem('userData', JSON.stringify(userData));

        // Store user data in MySQL
        try {
          await axios.post('https://api.artwalle.pattanaikdatapoint.com/register', userData);
          console.log('User data stored in MySQL');
          navigate('/'); // Navigate to home page after successful Google sign-in
          window.location.reload(); // Reload the page after navigating
        } catch (error) {
          if (error.response && error.response.status === 400 && error.response.data === 'User is already registered.') {
            setAlreadyRegistered('User is already registered. Please log in.');
          } else {
            console.error('Error storing user data:', error);
            setPopupMessage('Failed to register with Google. Please try again later.'); // Set error message
          }
        }
      })
      .catch((error) => {
        console.error('Error during Google sign-in:', error);
        setPopupMessage('Failed to sign in with Google. Please try again later.'); // Set error message
      });
  };

  // Close popup
  const closePopup = () => {
    setPopupMessage(null);
  };

  return (
    <div className="register-main-container">
      {/* Display error message */}
      {popupMessage && (
        <PopupErrorNotification message={popupMessage} onClose={closePopup} />
      )}

      <div className="container">
        <div className="header">
          <h2 className="headerText">Register</h2>
        </div>
        <div className="UserNameDetail">
          <div className="formGroup">
            <label className="label">First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="input"
            />
          </div>
          <div className="formGroup">
            <label className="label">Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="input"
            />
          </div>
        </div>

        <div className="formGroup">
          <label className="label">10 Digit Mobile Number</label>
          <input
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="input"
          />
          {mobileError && <p className="error">{mobileError}</p>}
        </div>

        <div className="formGroup">
          <label className="label">Email ID (User Name)</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          {emailError && <p className="error">{emailError}</p>}
        </div>
        <div className="formGroup">
          <label className="label">Password (Minimum 6 Characters)</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
          {passwordError && <p className="error">{passwordError}</p>}
        </div>

        <div className="actionButtons">
          <div className="register">
            <Button2 onClick={handleRegister} className="registerButton" style={{width:'100%'}}>Register</Button2>
            {alreadyRegistered && <p className="error">{alreadyRegistered}</p>}
            <button onClick={handleGoogleSignIn} className="googleButton">
              <img src={googleLogo} alt="Google" className="googleIcon" />
              Google
            </button>
          </div>
          <p><strong>------------ or ------------</strong></p>
          <button onClick={() => navigate('/login')} className="loginButton">Login to your account</button>
        </div>
      </div>
    </div>
  );
};

export default Register;
