import React from 'react'
import Cart from '../Assets/Cart/Cart'

const CartPage = () => {

       const products = [
      {
        id: 1,
        name: 'Custom Printed T-Shirt',
        printArea: 20,
        dimension: 'L',
        material: 'Cotton',
        quantity: 3,
        price: 200,
      },
      {
        id: 2,
        name: 'Mug with Logo',
        printArea: 10,
        dimension: '300ml',
        material: 'Ceramic',
        quantity: 5,
        price: 150,
      },
      {
        id: 3,
        name: 'Printed Canvas Bag',
        printArea: 25,
        dimension: '15x15 inches',
        material: 'Canvas',
        quantity: 2,
        price: 250,
      },
      {
        id: 4,
        name: 'Customized Notebook',
        printArea: 30,
        dimension: 'A5',
        material: 'Paper',
        quantity: 10,
        price: 100,
      },
    ];
  
    return (
      <div style={{marginTop:'120px'}}>
      <h1>Shopping Cart</h1>
        {products.map(product => (
          <Cart key={product.id} product={product} />
        ))}
      </div>
    );
  };
  
  export default CartPage;