import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductCustomization.css'; // Import the CSS file for styling
import '../GlobalStyling/GlobalStyling.css'; // Import the CSS file for styling
import Button2 from '../Buttons/Button2';
import PopupErrorNotification from '../PopUp/PopupErrorNotification';

// Import images
import quickFixImage from './Untitled-1-01.png';
import nonWovenImage from './Untitled-1-02.png';
import peelAndStickImage from './Untitled-1-03.png';
import premiumTexturedImage from './Untitled-1-04.png';
import rodiesGlitterImage from './Untitled-1-05.png';
import teakTexturedImage from './Untitled-1-06.png';
import classicTexturedImage from './Untitled-1-05.png'; // Example: Check if this is correct
import otherImage from './Untitled-1-04.png'; // Example: Check if this is correct

const ProductCustomization = ({ materials, defaultMaterial }) => {
    const [height, setHeight] = useState(12); // State for height
    const [width, setWidth] = useState(12); // State for width
    const [quantity, setQuantity] = useState(1); // State for quantity
    const [selectedMaterial, setSelectedMaterial] = useState(defaultMaterial);
    const [popupMessage, setPopupMessage] = useState(null); // State for popup message
    const [materialPrice, setMaterialPrice] = useState(0); // State for selected material price

    const navigate = useNavigate();
    const scrollRef = useRef(null);

    // Handler for height change
    const handleHeightChange = (e) => {
        let value = parseInt(e.target.value, 10);
        if (value < 12) {
            setPopupMessage('Minimum height should be 12 inches');
        } else {
            setPopupMessage(null);
        }
        setHeight(value);
    };

    // Handler for width change
    const handleWidthChange = (e) => {
        let value = parseInt(e.target.value, 10);
        if (value < 12) {
            setPopupMessage('Minimum width should be 12 inches');
        } else {
            setPopupMessage(null);
        }
        setWidth(value);
    };

    // Handler for quantity change
    const handleQuantityChange = (e) => {
        let value = parseInt(e.target.value, 10);
        if (value < 1) {
            setPopupMessage('Minimum quantity should be 1');
        } else {
            setPopupMessage(null);
        }
        setQuantity(value);
    };

    // Handler for material selection
    const handleMaterialSelect = (material) => {
        setSelectedMaterial(material.value);
        setMaterialPrice(material.price); // Update material price
    };

    // Scroll functions (left and right)
    const scrollLeft = () => {
        if (scrollRef.current) {
            const cardWidth = scrollRef.current.children[0].clientWidth;
            scrollRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            const cardWidth = scrollRef.current.children[0].clientWidth;
            scrollRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' });
        }
    };

    // Calculate total area based on height and width
    const totalArea = (height / 12) * (width / 12);

    // Sample price per square foot (replace it with your logic)
    const pricePerSqFt = 10; // Assuming price per square foot is ₹10

    // Calculate total amount based on total area, price per square foot, selected material price, and quantity
    const totalAmount = Math.ceil(totalArea * (pricePerSqFt + materialPrice) * quantity);

    // Timer for increment/decrement
    const [timer, setTimer] = useState(null);

    const handleMouseDown = (changeFunc, value) => {
        changeFunc((prev) => Math.max(12, prev + value)); // Immediate change on mouse down
        setTimer(setInterval(() => {
            changeFunc((prev) => Math.max(12, prev + value));
        }, 200)); // Change every 200ms while holding
    };

    const handleMouseDownQuantity = (changeFunc, value) => {
        changeFunc((prev) => Math.max(1, prev + value)); // Immediate change on mouse down
        setTimer(setInterval(() => {
            changeFunc((prev) => Math.max(1, prev + value));
        }, 200)); // Change every 200ms while holding
    };

    const handleMouseUp = () => {
        clearInterval(timer); // Clear the interval when mouse is released
    };

    // Close popup
    const closePopup = () => {
        setPopupMessage(null);
    };

    // Handle checkout button click
    const handleCheckout = () => {
        // Prepare product details to pass to cart page
        const productDetails = {
            productName: 'Custom Printed Wallpaper', // Replace with actual product name logic
            size: `${height}x${width} inches`,
            dimension: 'A5', // Replace with actual dimension logic
            material: selectedMaterial,
            quantity: quantity,
            totalPrice: totalAmount,
        };

        // Navigate to cart page and pass product details as state
        navigate('/cart', { state: { productDetails } });
    };

    return (
        <div className="product-customization">
            {popupMessage && (
                <PopupErrorNotification message={popupMessage} onClose={closePopup} />
            )}

            <div className="material-selection">
                <div className="material-selection-title">
                    <h4>Choose a material</h4>
                    <div className='material-selection-scroll-button'>
                        <button className="scroll-button left" onClick={scrollLeft}>&lt;</button>
                        <button className="scroll-button right" onClick={scrollRight}>&gt;</button>
                    </div>
                </div>
                <div className="material-selection-container">
                    <div className="material-options" ref={scrollRef}>
                        {materials.map((material) => (
                            <div
                                key={material.value}
                                className={`material-option ${selectedMaterial === material.value ? 'selected' : ''}`}
                                onClick={() => handleMaterialSelect(material)}
                            >
                                <img src={material.image} alt={material.label} width="100" />
                                <p className='material-info-title'>{material.label}</p>
                                <p className='material-info-price'>+ ₹{material.price}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="input-fields">
                <h4>Choose Wallpaper Size</h4>
                <div className="choose-size">
                    <div className="input-group">
                        <label htmlFor="height">Height (inches)</label>
                        <div className="custom-number-input">
                            <button
                                className='button-minus'
                                onMouseDown={() => handleMouseDown(setHeight, -1)}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >-</button>
                            <input
                                type="number"
                                id="height"
                                value={height}
                                onChange={handleHeightChange}
                                min="1" // Allow minimum value to be less than 12
                                step="1"
                            />
                            <button
                                className='button-plus'
                                onMouseDown={() => handleMouseDown(setHeight, 1)}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >+</button>
                        </div>
                    </div>

                    <div className="input-group">
                        <label htmlFor="width">Width (inches)</label>
                        <div className="custom-number-input">
                            <button
                                className='button-minus'
                                onMouseDown={() => handleMouseDown(setWidth, -1)}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >-</button>
                            <input
                                type="number"
                                id="width"
                                value={width}
                                onChange={handleWidthChange}
                                min="1" // Allow minimum value to be less than 12
                                step="1"
                            />
                            <button
                                className='button-plus'
                                onMouseDown={() => handleMouseDown(setWidth, 1)}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >+</button>
                        </div>
                    </div>
                </div>

                <div className="choose-quantity">
                    <h4>Choose Quantity</h4>
                    <div className="input-group">
                        <div className="custom-number-input">
                            <button
                                className='button-minus'
                                onMouseDown={() => handleMouseDownQuantity(setQuantity, -1)}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >-</button>
                            <input
                                type="number"
                                id="quantity"
                                value={quantity}
                                onChange={handleQuantityChange}
                                min="1"
                                step="1"
                            />
                            <button
                                className='button-plus'
                                onMouseDown={() => handleMouseDownQuantity(setQuantity, 1)}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >+</button>
                        </div>
                    </div>
                    <div className="total-area">
                    </div>
                </div>
            </div>

            <div className="total-amount-section">
                <h4 className="total-amount">Total Amount:  <strong>₹ {totalAmount.toFixed(2)}</strong> 
                   <span> (for {totalArea.toFixed(2)}  sq.ft.)</span>
                </h4>
                <Button2 style={{width:'150px', height:'60px'}} onClick={handleCheckout}>Add To Cart</Button2>
                <Button2 style={{width:'150px', height:'60px'}} onClick={handleCheckout}>Proceed to Check-Out</Button2>
            </div>
        </div>
    );
};

export default ProductCustomization;

// Materials data
export const materials = [
    { value: 'quick_fix', label: 'Quick Fix', price: 200, image: quickFixImage },
    { value: 'non_woven', label: 'Non-woven', price: 0, image: nonWovenImage },
    { value: 'peel_and_stick', label: 'Peel & Stick', price: 41, image: peelAndStickImage },
    { value: 'premium_textured', label: 'Premium', price: 73, image: premiumTexturedImage },
    { value: 'rodies_glitter', label: 'Rodies Glitter', price: 67, image: rodiesGlitterImage },
    { value: 'teak_textured', label: 'Teak Textured', price: 54, image: teakTexturedImage },
    { value: 'classic_textured', label: 'Classic', price: 62, image: classicTexturedImage },
    { value: 'other', label: 'Other', price: 0, image: otherImage },
];
