import React from 'react'
import AccordionFAQ from '../../Assets/Accordion/AccordionFAQ';

const FrequentlyAskedQuestions = () => {

    const items = [
        {
            title: 'Can I order samples online?',
            content: 'You can place an order to receive samples for our premium wallpapers.'
        },
        {
            title: 'Are there any installation services for wallpapers and blinds?',
            content: 'We have installation services for wallpapers and blinds in cities such as Mumbai, Pune, Bangalore, Chennai, Hyderabad, and Delhi. Residents outside these cities can contact our sales team on the toll-free number +91 xxxxx xxxxx or via WhatsApp chat.'
        },
        {
            title: 'How to measure wallpaper for walls?',
            content: 'Use a measuring tape to measure the walls’ length and width. Type in the wallpaper calculator to know how many wallpaper rolls or sq.ft of wallpaper you require'
        },
        {
            title: 'Use a measuring tape to measure the walls’ length and width. Type in the wallpaper calculator to know how many wallpaper rolls or sq.ft of wallpaper you require.',
            content: 'Upon placing your order, our design team will promptly provide digital proof, exclusive to customised wallpapers and blinds. Approving this digital proof is crucial, ensuring the final product aligns perfectly with your vision. Once you agree on the digital proof, we will dispatch your order within 48 hours, providing a seamless and efficient process from customisation to delivery.'
        },
        {
            title: 'Can I cancel/ return my order if I am dissatisfied?',
            content: 'You can cancel your order if you are dissatisfied. Upon cancellation, a full refund will be initiated, and you can expect the amount to be reflected in your bank account within 4-7 working days.'
        },
        {
            title: 'How can your design team assist me?',
            content: 'You can get assistance from our design team to transform your walls with the criteria. Image Edits: colour and rotate, Image quality enhancement, Image resize, crop, scale and Measurement & order assistance.'
        },
        {
            title: 'Can I get personalised recommendations for Artwalle products based on my preferences?',
            content: 'To provide personalised recommendations, we offer a home visit service where you can book an appointment with a Artwalle representative. This representative will visit your home to give personalised home decor solutions. During this visit, our expert will assess your space, understand your style, and provide customised advice to meet your home decor requirements. Feel free to ask for solutions, discuss your preferences, and explore the enchanting possibilities that Magic Decor can bring to your living spaces.'
        },
        // {
        //     title: '',
        //     content: ''
        // },
        
    ];

    return (
        <div style={{ maxWidth: '1300px', padding: '50px' }}>
            <AccordionFAQ items={items} />
        </div>)
}

export default FrequentlyAskedQuestions
