import React from 'react';
import './TermsAndConditions.css'; // Assuming you have a CSS file for styles

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <h1>Terms & Conditions</h1>

            <section>
                <h2>1. Use of the Platform</h2>
                <p>
                    Welcome to www.artwalle.com  (“<strong> Site</strong>”). The website www.artwalle.com is owned and operated by Bindu Creative  (“<strong> Company </strong>”), structured under the sole proprietorship with its registered office at Jagamara, Bhubaneswar, Odisha 751030. Access to our Site from any device, whether computer or mobile (through iOS or Android applications), is governed by these Terms of Use.
                </p>
                <p>
                    The Site is intended for personal, non-commercial use. Your use of the Site and its features is governed by these Terms of Use along with the Privacy Policy, Shipping Policy, and Cancellation, Refund, and Return Policy (collectively “Policies”), which may be updated periodically.
                </p>
                <p>
                    By accessing or using the Site, you agree to be bound by these Terms of Use and the Policies. <strong>ACCESSING, BROWSING, OR OTHERWISE USING THE SITE INDICATES YOUR UNCONDITIONAL AGREEMENT TO ALL THE TERMS AND CONDITIONS IN THIS AGREEMENT, SO PLEASE READ THIS AGREEMENT CAREFULLY BEFORE PROCEEDING.</strong> If you do not agree to any of these terms, please refrain from using the Site. Ensure your access and usage comply with all applicable laws in your jurisdiction.
                </p>
                <p>
                    The Company reserves the right to modify the Terms of Use or Policies at any time, without notice. Changes are effective immediately upon posting on the Site. Your continued use of the Site signifies your acceptance of the updated Terms of Use and Policies.
                </p>
            </section>

            <section>
                <h2>2. Privacy Practices</h2>
                <p>
                    We prioritize safeguarding your personal information and have formulated a Privacy Policy to protect it. Your use of the Site is also governed by this Privacy Policy. Continued use implies your acceptance of the Privacy Policy and consent to the use of your personal information as described.                </p>
            </section>

            <section>
                <h2>3. Your Account</h2>
                <p>
                    This Site is intended for adult users. Minors accessing the Site should do so under parental supervision. The Company does not knowingly collect information from minors. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You accept responsibility for all activities that occur under your account and password. The information you provide must not infringe on the rights of third parties.
                </p>
                <p>
                    If you suspect your account security has been breached, contact us immediately. We may require password changes or temporarily block or suspend your account without liability.
                </p>
                <p>
                    We reserve the right to refuse service and terminate accounts without notice if the Terms of Use are violated. You are responsible for all content uploaded or transmitted via the Site, maintained in accordance with our Privacy Policy                </p>
            </section>

            <section>
                <h2>4. Product Information</h2>
                <p>
                    We strive for accuracy in product descriptions on the Site but do not warrant that descriptions, colors, or other content are error-free. Product images are indicative and may not exactly match the actual product.
                </p>
            </section>

            <section>
                <h2>5. Product Use</h2>
                <p>
                    Products and services on the Site, including samples, are for personal and professional use only and are not for resale.
                </p>
            </section>

            <section>
                <h2>6. Pricing Information</h2>
                <p>
                    <strong>Prices and availability of products are subject to change without notice. Errors may occur, and we reserve the right to correct inaccuracies and update information without prior notice. Orders may be canceled or modified due to pricing errors.</strong>
                </p>
            </section>

            <section>
                <h2>7. Cancellations, Refunds, and Returns</h2>
                <p>
                    Please refer to our Warranty and Support page on the Site for details on cancellations, refunds, and returns.
                </p>
            </section>

            <section>
                <h2>8. Mode of Payment</h2>
                <p>
                    Payments can be made via Credit Cards, Debit Cards, Net Banking, Wallets, e-Gift cards, and reward points. Credit card, Debit Card, and Net Banking are recommended for faster order processing.
                </p>
            </section>

            <section>
                <h2>9. Intellectual Property Rights</h2>
                <p>
                    All content, trademarks, and data on the Site, including logos, text, graphics, icons, and software, are the property of the Company and protected by Indian and international intellectual property laws. Unauthorized use is prohibited.                </p>
            </section>

            <section>
                <h2>10. Limited License</h2>
                <p>
                    The Company grants you a limited, non-exclusive, non-transferable license to access and make personal use of the Site. All rights not expressly granted are reserved by the Company.                </p>
            </section>

            <section>
                <h2>11. Representations and Warranties</h2>
                <p>
                    The Site is provided “AS IS” without warranties of any kind, either express or implied. We do not warrant uninterrupted access or use of the Site.
                </p>
            </section>

            <section>
                <h2>12. Disclaimer of Warranty and Limitation of Liability</h2>
                <p>
                    The Site is provided “AS IS” without warranties of any kind. We are not liable for any damages arising from the use of the Site.
                </p>
            </section>

            <section>
                <h2>13. Termination</h2>
                <p>
                    These Terms of Use are effective until terminated by either you or the Company. You may terminate by discontinuing use of the Site. The Company may terminate without notice and deny access to the Site.
                </p>
            </section>

            <section>
                <h2>14. Indemnity</h2>
                <p>
                    You agree to indemnify and hold harmless the Company from any claims, liabilities, damages, losses, costs, and expenses arising from your use of the Site or breach of these Terms of Use.                </p>
            </section>

            <section>
                <h2>15. Governing Law and Jurisdiction</h2>
                <p>
                    These Terms of Use and the Policies are governed by Indian law. Any disputes shall be subject to the exclusive jurisdiction of the courts in Bhubaneswar.
                </p>
            </section>

            <section>
                <h2>16. Site Security</h2>
                <p>
                    Violations of Site security may result in civil or criminal liability. The Company may investigate and cooperate with law enforcement in prosecuting violations.                </p>
            </section>

            <section>
                <h2>17. Entire Agreement</h2>
                <p>
                    These Terms of Use constitute the entire agreement between you and the Company regarding the use of the Site.
                </p>
            </section>
        </div>
    );
};

export default TermsAndConditions;
