// src/Functions/ImportImages.js
function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

// Adjust the path to point correctly to your images directory
const images = importAll(require.context('../Images/PhotoFrame', false, /\.(png|jpe?g|svg)$/));

export default images;
