import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ProductListMasonry.css'; // Import CSS file
import Button2 from '../Buttons/Button2';

const ProductListMasonry = () => {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerRow = 4; // Number of items per row
    const rowsToShow = 3; // Number of rows to display
    const navigate = useNavigate();
    const productRefs = useRef([]); // To keep references to each product element
    const tabHeaderRefs = useRef([]); // To keep references to each tab header element
    const tabTitleH1Ref = useRef(null); // To keep reference to tab title H1 element
    const tabTitleH3Ref = useRef(null); // To keep reference to tab title H3 element

    const tabHeaders = ["Premium Wallpapers", "Wallpaper Rolls", "Printings", "Canvas"];
    const tabTaglines = [
        "Transform your space with our stunning collection of wallpapers",
        "High-quality rolls for a perfect finish",
        "Beautiful prints for every space",
        "Canvas art that inspires"
    ];

         useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://api.artwalle.pattanaikdatapoint.com/productlist');
                setProducts(response.data);
                filterProductsByTab(tabIndex, response.data);
            } catch (error) {
                setError('Error fetching products');
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, [tabIndex]); // Trigger fetch on tab index change


    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                } else {
                    entry.target.classList.remove('slide-in');
                }
            });
        });

        // Observe product items
        productRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        // Observe tab headers
        tabHeaderRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        // Observe tab title H1 and H3
        if (tabTitleH1Ref.current) observer.observe(tabTitleH1Ref.current);
        if (tabTitleH3Ref.current) observer.observe(tabTitleH3Ref.current);

        return () => {
            if (productRefs.current) {
                productRefs.current.forEach(ref => {
                    if (ref) observer.unobserve(ref);
                });
            }
            if (tabHeaderRefs.current) {
                tabHeaderRefs.current.forEach(ref => {
                    if (ref) observer.unobserve(ref);
                });
            }
            if (tabTitleH1Ref.current) {
                observer.unobserve(tabTitleH1Ref.current);
            }
            if (tabTitleH3Ref.current) {
                observer.unobserve(tabTitleH3Ref.current);
            }
        };
    }, [filteredProducts]);

    const filterProductsByTab = (index, products) => {
        const selectedCategory = tabHeaders[index];
        const filtered = products.filter(product => product.category === selectedCategory);
        setFilteredProducts(filtered);
    };

    // const formatImagePath = (path) => {
    //     if (!path) return '/ProductImages/default-image.jpeg'; // Fallback image
    //     const filename = path.split('\\').pop(); // Extract the filename from the absolute path
    //     return `/ProductImages/${filename}`;
    // };

    const formatImagePath = (path) => {
        if (!path) return '/ProductImages/default-image.jpeg'; // Fallback image
        const filename = path.split('\\').pop(); // Extract the filename from the absolute path
        return `https://artwalle.com/ProductImages/${filename}`;
    };
    

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleProductClick = (id, category) => {
        switch (category) {
            case "Printings":
                navigate(`/CustomizedPrintings/${id}`);
                break;
            case "Canvas":
                navigate(`/CustomizedCanvas/${id}`);
                break;
            default:
                navigate(`/ProductList/${id}`);
        }
    };

    const startIndex = 0;
    const endIndex = startIndex + 9; // Display only 9 items
    const currentItems = filteredProducts.slice(startIndex, endIndex);

    const gridContainerStyle = {
        gridTemplateRows: `repeat(${rowsToShow}, 1fr)`,
    };

    const gridItemStyle = (index) => ({
        gridColumn: `span ${index === 0 ? 2 : 1}`, // First item spans 2 columns
        gridRow: `span ${index === 0 ? 2 : 1}`, // First item spans 2 rows
    });

    return (
        <div className='tab-container'>
            <div className="tab-container-layout">
            <div className="tab-headers">
                    {tabHeaders.map((header, index) => (
                        <div
                            key={index}
                            className={`tab-header ${tabIndex === index ? 'active' : ''}`}
                            onClick={() => setTabIndex(index)}
                            ref={el => tabHeaderRefs.current[index] = el}
                        >
                            {header}
                        </div>
                    ))}
                </div>
                <div className="tab-title">
                    <h1 className="tab-title-h1" ref={tabTitleH1Ref}>{tabHeaders[tabIndex]}</h1>
                    <h3 className="tab-title-h3" ref={tabTitleH3Ref}>{tabTaglines[tabIndex]}</h3>
                </div>
            </div>
            <div style={gridContainerStyle} className='grid-container'>
                {currentItems.map((product, index) => (
                    <div key={product.id} style={gridItemStyle(index)} className='grid-item' ref={el => productRefs.current[index] = el}>
                        <img
                            src={formatImagePath(product.product_img1)}
                            alt=""
                            onClick={() => handleProductClick(product.id, product.category)}
                        />
                        <div className='card-details' onClick={() => handleProductClick(product.id, product.category)}>
                            <div className='card-details-title'>{product.product_name}</div>
                            <div className='card-details-price'>&#8377; {product.product_price}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="product-list-masonry-button">
                <Button2>View All</Button2>
            </div>
        </div>
    );
};

export default ProductListMasonry;
