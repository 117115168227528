import React, { useState } from 'react';
import '../Icons/ShareAndFavorites.css'; // Assuming you have a CSS file for custom styles

const ShareAndFavorite = ({ onShare, onFavorite, isFavorite = false }) => {
  const [favorite, setFavorite] = useState(isFavorite);

  const handleFavoriteClick = () => {
    const newFavoriteStatus = !favorite;
    setFavorite(newFavoriteStatus);
    if (onFavorite) {
      onFavorite(newFavoriteStatus);
    }
  };

  return (
    <div className="icon-container">
      <div className="icon-button" onClick={onShare} title="Share">
        <svg className="icon share-icon" viewBox="0 0 24 24">
          <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.02-4.11c.53.48 1.22.77 1.97.77 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7l-7.02 4.11c-.53-.48-1.22-.77-1.97-.77-1.66 0-3 1.34-3 3s1.34 3 3 3c.75 0 1.44-.29 1.97-.77l7.12 4.15c-.05.22-.09.45-.09.69 0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3z"/>
        </svg>
      </div>
      <div className="icon-button" onClick={handleFavoriteClick} title="Add to Favorites">
        {favorite ? (
          <svg className="icon favorite-icon" viewBox="0 0 24 24">
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
          </svg>
        ) : (
          <svg className="icon favorite-border-icon" viewBox="0 0 24 24">
            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm0 15.55l-.1.1-.1-.1C11.14 14.24 4 9.75 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 1.25-7.14 5.74-11.9 10.05z"/>
          </svg>
        )}
      </div>
    </div>
  );
};

export default ShareAndFavorite;
