import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Card1 from '../../Assets/Card/Card1';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchBar from './SearchBar';
import Fuse from 'fuse.js';
import './SearchPage.css'; 

const SearchPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedPriceRange, setSelectedPriceRange] = useState('All');
  const [sortOption, setSortOption] = useState('name');
  const itemsPerPage = 18;
  const navigate = useNavigate();
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const actionBarRef = useRef(null);
  const productsGridRef = useRef(null);

  const tabHeaders = ["Premium Wallpapers", "Wallpaper Rolls", "Printings", "Canvas"];
  const tabTaglines = [
    "Transform your space with our stunning collection of wallpapers.",
    "High-quality rolls for a perfect finish.",
    "Beautiful prints for every space.",
    "Canvas art that inspires."
  ];

  const fetchProducts = async (page) => {
    try {
      const response = await axios.get('https://api.artwalle.pattanaikdatapoint.com/ProductList', {
        params: {
          _page: page,
          _limit: itemsPerPage,
        },
      });
      setProducts(prev => [...prev, ...response.data]);
    } catch (error) {
      setError('Error fetching products');
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchProducts(1);
  }, []);

  useEffect(() => {
    if (location.state && location.state.images) {
      setSearchResults(location.state.images);
    } else if (location.search) {
      const query = new URLSearchParams(location.search).get('query');
      if (query) {
        searchProducts(query);
      }
    }
  }, [location]);

  const searchProducts = async (searchTerm) => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.artwalle.pattanaikdatapoint.com/ProductList');
      const fuse = new Fuse(response.data, {
        keys: ['product_name'],
        threshold: 0.3,
      });
      const result = fuse.search(searchTerm);
      const uniqueResults = Array.from(new Set(result.map(({ item }) => item)));
      setSearchResults(uniqueResults);
    } catch (error) {
      setError('Error fetching search results');
    } finally {
      setLoading(false);
    }
  };

  // const formatImagePath = (path) => {
  //   if (!path) return '/ProductImages/default-image.jpeg'; 
  //   const filename = path.split('\\').pop(); 
  //   return `/ProductImages/${filename}`;
  // };

  const formatImagePath = (path) => {
    if (!path) return '/ProductImages/default-image.jpeg'; // Fallback image
    const filename = path.split('\\').pop(); // Extract the filename from the absolute path
    return `https://artwalle.com/ProductImages/${filename}`;
};

  const handleProductClick = (id) => {
    const selectedTabCategory = tabHeaders[tabIndex];
    if (selectedTabCategory === "Printings") {
      navigate(`/CustomizedPrintings/${id}`);
    } else if (selectedTabCategory === "Canvas") {
      navigate(`/CustomizedCanvas/${id}`);
    } else {
      navigate(`/ProductList/${id}`);
    }
  };

  const handleScroll = () => {
    if (actionBarRef.current) {
      setIsSticky(window.scrollY > actionBarRef.current.offsetTop);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const loadMoreProducts = () => {
    setLoadingMore(true);
    setPage(prevPage => {
      fetchProducts(prevPage + 1);
      return prevPage + 1;
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const filterAndSortProducts = () => {
    let filtered = [...products];

    if (selectedCategory !== 'All') {
      filtered = filtered.filter(product => product.category === selectedCategory);
    }

    if (selectedPriceRange !== 'All') {
      filtered = filtered.filter(product => {
        const price = product.product_price;
        if (selectedPriceRange === 'Under $50') return price < 50;
        if (selectedPriceRange === '$50 - $100') return price >= 50 && price <= 100;
        if (selectedPriceRange === 'Over $100') return price > 100;
        return true;
      });
    }

    if (sortOption === 'name') {
      filtered.sort((a, b) => a.product_name.localeCompare(b.product_name));
    } else if (sortOption === 'newArrivals') {
      filtered.sort((a, b) => new Date(b.arrival_date) - new Date(a.arrival_date)); 
    } else if (sortOption === 'popular') {
      filtered.sort((a, b) => b.ratings - a.ratings); 
    }

    return filtered;
  };

  const combinedProducts = filterAndSortProducts();

  const productRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target); // Stop observing once animation is triggered
          }
        });
      },
      { threshold: 0.1 }
    );

    productRefs.current.forEach(ref => ref && observer.observe(ref));

    return () => {
      productRefs.current.forEach(ref => ref && observer.unobserve(ref));
    };
  }, [combinedProducts]);

  
  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <div className="error-message">{error}</div>;



  return (
    <div className="search-page-container">
      <div
        className={`action-bar ${isSticky ? 'sticky' : ''}`}
        ref={actionBarRef}
      >
        <div className="search-action">
          <SearchBar />
        </div>
        <div className="filter-bar">
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="All">All Categories</option>
            <option value="Premium Wallpapers">Premium Wallpapers</option>
            <option value="Wallpaper Rolls">Wallpaper Rolls</option>
            <option value="Printings">Printings</option>
            <option value="Canvas">Canvas</option>
          </select>

          <select value={selectedPriceRange} onChange={(e) => setSelectedPriceRange(e.target.value)}>
            <option value="All">All Prices</option>
            <option value="Under $50">Under $50</option>
            <option value="$50 - $100">$50 - $100</option>
            <option value="Over $100">Over $100</option>
          </select>

          <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
            <option value="name">Sort by Name (A-Z)</option>
            <option value="newArrivals">Sort by New Arrivals</option>
            <option value="popular">Sort by Popularity</option>
          </select>
        </div>
      </div>
      <div className="products-grid">
        {combinedProducts.map((product, index) => (
          <div
            className="product-card"
            key={product.id}
            ref={el => productRefs.current[index] = el}
          >
            <Card1
              imageUrl={formatImagePath(product.product_img1)}
              productName={product.product_name}
              previousPrice={product.product_price}
              sellingPrice={product.product_price}
              ratings={product.ratings}
              onClick={() => handleProductClick(product.id)}
            />
          </div>
        ))}
      </div>

      {loadingMore && <div className="loading-spinner"></div>}
    </div>
  );
};

export default SearchPage;