// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Webpages/HomePage';
import WallpaperRolls from './Webpages/WallpaperRolls';
import NavigationBar from './Components/NavigationBar/NavigationBar';
import ProductList from './Components/Products/ProductList';
import ProductDetailsPremiunWallpapers from './Assets/ProductDetails/ProductDetailsPremiunWallpapers';
import productImages from './Assets/ProductDetails/ProductDetailsPremiunWallpapers';
import { Grid } from '@mui/material';
import Footer from './Assets/Footer/Footer';
import SearchPage from './Assets/SearchBar/SearchPage';
import Breadcrumb from './Assets/NavBar/BreadCrumb';
import CustomisedPrintings from './Webpages/CustomisedPrintings';
import CustomisedCanvasPrinting from './CustomisedCanvasPrinting';
import CartPage from './Webpages/CartPage';
import Register from './Assets/RegisterAndLogin/Register';
import Login from './Assets/RegisterAndLogin/Login';
import ContactBar from './Assets/StickyElements/ContactBar';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Wallpapers from './Webpages/Wallpapers';
import AdminProductDetails from './Assets/DashBoard/AdminProductDetails';
import TermsAndConditions from './Components/Policy/TermsAndConditions';
import ReturnRefundCancellationPolicy from './Components/Policy/ReturnRefundCancellationPolicy';
import PaymentAndShippment from './Components/Policy/PaymentAndShippment';


const App = () => {
  return (
    <Grid container lg={12} justifyContent="center" overflow={'hidden'}>
      <Router>
        <NavigationBar />
        {/*
        <Breadcrumb />
        */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/ReturnRefundCancellationPolicy" element={<ReturnRefundCancellationPolicy />} />
          <Route path="/PaymentAndShippment" element={<PaymentAndShippment />} />
          <Route path="/wallpaper-rolls" element={<WallpaperRolls />} />
          <Route path="/printings" element={<HomePage />} />
          <Route path="/about" element={<HomePage />} />
          <Route path="/ProductList" element={<ProductList />} />
          <Route path="/ProductList/:id" element={<ProductDetailsPremiunWallpapers images={productImages} />} />
          <Route path="/Searched" element={<SearchPage />} />
          <Route path="/Wallpapers" element={<Wallpapers />} />
          <Route path="/customised_printings" element={<CustomisedPrintings />} />
          <Route path="/customised_canvas-printing" element={<CustomisedCanvasPrinting />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adminProductDetails" element={<AdminProductDetails />} />
        </Routes>
        <ContactBar/>
        <Footer />
      </Router>
    </Grid>
  );
}

export default App;
