import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Box, FormHelperText, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import importAll from '../PictureUpload/imageLoader';

const images = importAll(require.context('../../../../Images/MountPaper', false, /\.(png|jpe?g|svg)$/));

const BackgroundMountSelector = ({ onImageSelect }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const selectedImageUrl = event.target.value;
    console.log("Selected Image URL:", selectedImageUrl);
    onImageSelect(selectedImageUrl);
    setSelectedImage(selectedImageUrl);
  };


  return (
    <Box textAlign="center">
     {/*
       <Typography style={{textAlign:'left'}} mt={2} mb={1}>Backgroung Mount</Typography>
       */}

      <Box display="flex" justifyContent="space-between" flexWrap="wrap" columnGap={1} rowGap={2}>
        {Object.entries(images).map(([key, value]) => (
          <img
            key={key}
            src={value}
            alt={key}
            height={50}
            style={{
              boxShadow: '1px 1px 4px',
              transition: '0.3s ease', // Transition for hover effect
              cursor: 'pointer', // Show pointer cursor on hover
            }}
            onClick={() => {
              onImageSelect(value);
              setSelectedImage(value);
            }}
            onMouseEnter={(e) => {
              e.target.style.transform = 'scale(1.1)';  // Change border color on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = 'scale(1)';  // Change border color on hover

            }}
          />
        ))}
      </Box>

    </Box>
  );
};

export default BackgroundMountSelector;
