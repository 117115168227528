import React from 'react';
import './Card1.css';

const Card1 = ({ imageUrl, productName, previousPrice, sellingPrice, ratings, onClick }) => {
    return (
        <div className="card1-root" onClick={onClick} style={{ cursor: 'pointer' }}>
            <img src={imageUrl} alt={productName} className="card1-image" />
            <div className="card1-content">
                <button aria-label="add to favorites" className="card1-favoriteButton">
                    <span className="material-icons">favorite_border</span>
                </button>
                <div className="card1-productName">{productName}</div>
                <div className="card1-ratings">
                    {[...Array(ratings)].map((_, index) => (
                        <span key={index} className="material-icons card1-star">star</span>
                    ))}
                </div>
                <div className="card1-price">
                    {previousPrice && <span className="card1-previousPrice">{previousPrice}</span>}
                    {sellingPrice}
                </div>
            </div>
        </div>
    );
};

export default Card1;
