// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA-a5yL-MV1tzFYMMdSFsyr2YEWbuCC8n4",
  authDomain: "artwalle-pdp.firebaseapp.com",
  projectId: "artwalle-pdp",
  storageBucket: "artwalle-pdp.appspot.com",
  messagingSenderId: "714347746937",
  appId: "1:714347746937:web:db41893f917c3b504326a8",
  measurementId: "G-YYLJT1SZ14"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
