import React, { useState, useEffect, useRef } from 'react';
import './AccordionFAQ.css';

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleScroll = () => {
    const accordionItems = document.querySelectorAll('.accordion-item');
    accordionItems.forEach((item) => {
      const rect = item.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        item.classList.add('visible');
        item.classList.remove('hidden');
      } else {
        item.classList.remove('visible');
        item.classList.add('hidden');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll(); // Initial check in case items are already in view
  }, []);

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          index={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={toggleOpen}
        />
      ))}
    </div>
  );
};

const AccordionItem = ({ index, title, content, isOpen, onClick }) => {
  const handleToggle = () => {
    onClick(index);
  };

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={handleToggle}>
        <h2>{title}</h2>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      <div className={`accordion-content ${isOpen ? 'open' : ''}`}>
        {content}
      </div>
    </div>
  );
};

export default Accordion;
