import React, { useState } from 'react';
import './Button4.css'; // Import the CSS file

const Button4 = ({ onClick, children, style }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={`button-container ${isHovered ? 'hover' : ''}`} style={style}>
      <button
        onClick={onClick}
        className="button"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </button>
    </div>
  );
};

export default Button4;
