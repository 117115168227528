import React, { useState } from 'react';
import './Button2.css'; // Import the CSS file

const Button2 = ({ onClick, children, style }) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    borderColor: isHovered ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.6)',
    ...style, // Allow custom styles to override defaults
  };

  return (
    <div className="button-container-2">
      <button
        onClick={onClick}
        className="button-2"
        style={buttonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </button>
    </div>
  );
};

export default Button2;
