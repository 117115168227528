import React, { useState, useEffect } from 'react';
import {
  Slider,
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
  Typography,
  Box,
  Collapse,
  Dialog
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  Crop as CropIcon,
  Wallpaper as WallpaperIcon,
  AspectRatio as AspectRatioIcon,
  ShoppingCart as ShoppingCartIcon
} from '@mui/icons-material';
import DraggableImage from '../CostomizationComponents/DragableImage/DraggableImage';
import './PhotoFrame.css';
import BackgroundMountSelector from '../CostomizationComponents/Mount/BackgroundMountSelector';
import BackgroundMountView from '../CostomizationComponents/Mount/BackgroundMountView';
import Frame from '../CostomizationComponents/Photoframe/Frame';
import FrameSelector from '../CostomizationComponents/Photoframe/FrameSelector';
import images from '../../../Functions/ImportImages';
import RoomImage2 from './room-1.jpg'
import RoomImage1 from './room-2.jpg'

import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const defaultSizes = [
  { label: '8x10', width: 8, height: 10, price: 199 },
  { label: '9x12', width: 9, height: 12, price: 299 },
  { label: '11x14', width: 11, height: 14, price: 399 },
  { label: '12x12', width: 12, height: 12, price: 499 },
  { label: '12x13', width: 12, height: 13, price: 599 },
  { label: '16x20', width: 16, height: 20, price: 699 },
  { label: '18x24', width: 18, height: 24, price: 799 },
  { label: '24x24', width: 24, height: 24, price: 899 },
  { label: '24x30', width: 24, height: 30, price: 999 },
  { label: 'A0', width: 33.1, height: 46.8, price: 1099 },
  { label: 'A1', width: 23.4, height: 33.1, price: 1199 },
  { label: 'A2', width: 16.5, height: 23.4, price: 1299 },
  { label: 'A3', width: 11.7, height: 16.5, price: 1399 },
  { label: 'A4', width: 8.3, height: 11.7, price: 1499 },
  { label: 'Custom', width: 12, height: 12, price: null }
];

const steps = [
  { label: 'Select Photo', icon: <PhotoCameraIcon /> },
  { label: 'Select Size and Mount Size', icon: <AspectRatioIcon /> },
  { label: 'Select Frame', icon: <CropIcon /> },
  { label: 'Select Background Mount', icon: <WallpaperIcon /> },
  { label: 'Proceed to Check out', icon: <ShoppingCartIcon /> }
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const PhotoFrame = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [frameSize, setFrameSize] = useState({ width: 12, height: 12 });
  const [centerImage, setCenterImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [centralImagePosition, setCentralImagePosition] = useState({ x: 0, y: 0 });
  const [centralImageScale, setCentralImageScale] = useState(1);
  const [selectedFrame, setSelectedFrame] = useState('');
  const [filteredFrames, setFilteredFrames] = useState({});
  const [mountSize, setMountSize] = useState(2);
  const [selectedSize, setSelectedSize] = useState('12x12');
  const [showCustomSliders, setShowCustomSliders] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const defaultFrame = '102'; // Replace with the actual default frame name
    setSelectedFrame(defaultFrame);
    updateFilteredFrames(defaultFrame);
  }, []);

  const updateFilteredFrames = (frameName) => {
    const frames = Object.keys(images)
      .filter((filename) => filename.startsWith(frameName))
      .reduce((result, filename) => {
        const name = filename.split('-')[1].replace('.png', '');
        result[name] = images[filename];
        return result;
      }, {});
    setFilteredFrames(frames);
  };

  const handleFrameSelect = (frameName) => {
    setSelectedFrame(frameName);
    updateFilteredFrames(frameName);
  };

  const convertInchesToPixels = (inches) => {
    return inches * (500 / 60); // Keeping it proportional
  };

  const calculateDimensions = (width, height) => {
    const maxDimension = 60;
    const aspectRatio = width / height;

    const displayedWidth = width > height ? maxDimension : maxDimension * aspectRatio;
    const displayedHeight = height > width ? maxDimension : maxDimension / aspectRatio;

    const scaleFactor = (width + height) / 2;

    return {
      displayedWidth: convertInchesToPixels(displayedWidth),
      displayedHeight: convertInchesToPixels(displayedHeight),
      scaleFactor
    };
  };

  const handleFrameWidthChange = (e, newValue) => {
    setFrameSize({ ...frameSize, width: newValue });
  };

  const handleFrameHeightChange = (e, newValue) => {
    setFrameSize({ ...frameSize, height: newValue });
  };

  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCenterImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleCentralImageDrag = (position) => {
    setCentralImagePosition(position);
  };

  const handleCentralImageZoom = (scale) => {
    setCentralImageScale(scale);
  };

  const handleMountSizeChange = (e, newValue) => {
    setMountSize(newValue);
  };

  const handleSizeSelect = (event) => {
    const selectedSize = defaultSizes.find(size => size.label === event.target.value);
    setSelectedSize(event.target.value);
    if (selectedSize) {
      setFrameSize({ width: selectedSize.width, height: selectedSize.height });
    }
    setShowCustomSliders(event.target.value === 'Custom');
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Calculate dimensions and scale factor
  const { displayedWidth, displayedHeight, scaleFactor } = calculateDimensions(frameSize.width, frameSize.height);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(scaleFactor)

  return (
    <Grid container spacing={2} alignItems="flex-start" justifyContent="center" height="100vh" padding={2} mt={10}>
      <Grid item xs={12} md={4}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel icon={step.icon}>{step.label}</StepLabel>
              <StepContent>
                {index === 0 && (
                  <Button variant="contained" component="label" sx={{ mb: 2 }}>
                    Upload Image
                    <input type="file" hidden onChange={handleImageUpload} />
                  </Button>
                )}
                {index === 1 && (

                  // Frame Selector      
                  <>
                    <Grid container spacing={1} mb={2} justifyContent={'space-between'}>
                      {defaultSizes.map(size => (
                        <Grid item key={size.label}>
                          <Button
                            variant="outlined"
                            onClick={() => handleSizeSelect({ target: { value: size.label } })}
                            style={{ display: 'flex', flexDirection: 'column', width: '6rem' }}
                          >
                            <Typography variant='body2' fontWeight={'bold'}>{size.label}</Typography>
                            <Typography variant='subtitle2'>₹{size.price}</Typography>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                    <Collapse in={showCustomSliders} timeout="auto" unmountOnExit>
                      <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={8}>
                          <Slider
                            value={frameSize.width}
                            onChange={handleFrameWidthChange}
                            aria-labelledby="width-slider"
                            min={8}
                            max={60}
                            step={1}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Frame Width (inches)"
                            type="number"
                            value={frameSize.width}
                            onChange={(e) => setFrameSize({ ...frameSize, width: Number(e.target.value) })}
                            fullWidth
                            inputProps={{ min: 8, max: 60 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={8}>
                          <Slider
                            value={frameSize.height}
                            onChange={handleFrameHeightChange}
                            aria-labelledby="height-slider"
                            min={8}
                            max={60}
                            step={1}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Frame Height (inches)"
                            type="number"
                            value={frameSize.height}
                            onChange={(e) => setFrameSize({ ...frameSize, height: Number(e.target.value) })}
                            fullWidth
                            inputProps={{ min: 8, max: 60 }}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>

                    <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={8}>
                        <Slider
                          value={mountSize}
                          onChange={handleMountSizeChange}
                          aria-labelledby="mount-size-slider"
                          min={0}
                          max={12}
                          step={2}
                          marks
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Mount Size (inches)"
                          type="number"
                          value={mountSize}
                          onChange={(e) => setMountSize(Number(e.target.value))}
                          fullWidth
                          inputProps={{ step: 2, min: 0, max: 12 }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {index === 2 && (

                  //Back Ground Mount Selecter
                  <FrameSelector selectedFrame={selectedFrame} onFrameSelect={handleFrameSelect} />

                )}
                {index === 3 && (
                  <BackgroundMountSelector onImageSelect={setSelectedImage} />


                )}
                {index === 4 && (
                  <Button variant="contained" color="primary" onClick={() => alert('Proceed to Checkout')}>
                    Proceed to Checkout
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleBack}
                  sx={{ mt: 2, ml: 2 }}
                  disabled={index === 0}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 2, ml: 2 }}
                  disabled={index === steps.length - 1}
                >
                  Next
                </Button>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>


      <Grid item xs={12} md={6} height={'90%'} display="flex" justifyContent="center" alignItems="center">
        <Grid>
          <Grid
            className="photoFrame"
            style={{
              display: 'flex',
              height: `${displayedHeight}px`,
              width: `${displayedWidth}px`,
              position: 'relative',
              transition: 'height 0.5s ease, width 0.5s ease',
            }}
          >
            {/*Height Marker*/}
            <Box
              position={'absolute'}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                height: `${displayedHeight}px`,
                width: '25px',
                right: '-40px',
                zIndex: '5',
                borderTop: '2px dotted rgb(13, 59, 102, .5)',
                borderBottom: '2px dotted rgb(13, 59, 102, .5)',
                borderRight: '2px dotted rgb(13, 59, 102, .5)',
                transition: '0.5s ease',
              }}
            >
              <Typography
                backgroundColor='rgb(238, 150, 75)'
                width={'100px'}
                position={'absolute'}
                right={-50}
                textAlign={'center'}
                borderRadius={50}
                color={'white'}
                style={{ rotate: '90deg' }}
              >
                {frameSize.height} inches
              </Typography>
            </Box>

            {/*Width Marker*/}
            <Box
              position={'absolute'}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                position: 'absolute',
                width: `${displayedWidth}px`,
                height: '25px',
                top: '-40px',
                zIndex: '5',
                borderTop: '2px dotted rgb(13, 59, 102, .5)',
                borderLeft: '2px dotted rgb(13, 59, 102, .5)',
                borderRight: '2px dotted rgb(13, 59, 102, .5)',
                transition: '0.5s ease',
              }}
            >
              <Typography
                backgroundColor='rgb(238, 150, 75)'
                width={'100px'}
                position={'absolute'}
                mt={-3.5}
                textAlign={'center'}
                borderRadius={50}
                color={'white'}
              >
                {frameSize.width} inches
              </Typography>
            </Box>

            <BackgroundMountView imageUrl={selectedImage} />

            <Frame frames={filteredFrames} />

            {centerImage && (
              <Grid item lg={12} padding={mountSize + 3.75} position="relative">
                <DraggableImage
                  src={centerImage}
                  isCentral={true}
                  frameWidth={displayedWidth}
                  frameHeight={displayedHeight}
                  position={centralImagePosition}
                  scale={centralImageScale}
                  onDrag={handleCentralImageDrag}
                  onZoom={handleCentralImageZoom}
                  showZoomControls={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>



      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >

        <Grid item xs={12} md={12} height={'100%'} display="flex" justifyContent="center" alignItems="center" overflow={'hidden'}>

          <Grid position={'absolute'} width={'100%'} height={'100%'} style={{ objectFit: 'cover' }}>
            <img src={RoomImage2} alt="" style={{ width: '100%', height: '100%' }} />
          </Grid>

          {/* Close button */}
          <IconButton
            edge="end"  // Align to the end of the container (right side)
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: 'absolute', top: 20, right: 30, color: '#e63946' }}  // Position top right
          >
            <CloseIcon />
            <Typography> Close </Typography>
          </IconButton>

          <Grid lg={12} position={'absolute'} justifyContent={'center'} top={0} height={'100%'} style={{ top: '0' }}>
            <Grid
              className="photoFrame"
              style={{
                display: 'flex',
                height: `${displayedHeight}px`,
                width: `${displayedWidth}px`,
                position: 'absolute',
                top: '5%',
                transition: 'height 0.5s ease, width 0.5s ease',
                transform: `scale(${scaleFactor / 60})`,
              }}
            >
              <BackgroundMountView imageUrl={selectedImage} />
              <Frame frames={filteredFrames} />

              {centerImage && (
                <Grid item lg={12} padding={mountSize + 3.75} position="relative">
                  <DraggableImage
                    src={centerImage}
                    isCentral={true}
                    frameWidth={displayedWidth}
                    frameHeight={displayedHeight}
                    position={centralImagePosition}
                    scale={centralImageScale}
                    onDrag={handleCentralImageDrag}
                    onZoom={handleCentralImageZoom}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

      </Dialog>






      <Button variant="outlined" onClick={handleClickOpen}>
        Wall View
      </Button>


    </Grid>
  );
};

export default PhotoFrame;
