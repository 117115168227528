// StaticStarRating.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const StarRatingStatic = ({ value = 0, maxStars = 5, reviewCount = 0 }) => {
  const starColor = "#FFBE0B"; // Yellow color

  return (
    <Box display="flex" alignItems="center" height={'auto'}>
      {[...Array(maxStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <Box key={index}>
            {value >= starValue ? (
              <StarIcon style={{ color: starColor }} />
            ) : value >= starValue - 0.5 ? (
              <StarHalfIcon style={{ color: starColor }} />
            ) : (
              <StarBorderIcon style={{ color: starColor }} />
            )}
          </Box>
        );
      })}
      <Typography variant="body1" style={{ marginLeft: 8 }}>
        ({reviewCount} {reviewCount === 1 ? "review" : "reviews"})
      </Typography>
    </Box>
  );
};

export default StarRatingStatic;
