import React from 'react';
import images from '../../../../Functions/ImportImages';
import { Grid, Typography } from '@mui/material';

const FrameSelector = ({ selectedFrame, onFrameSelect }) => {
  const uniqueNumbers = Array.from(new Set(
    Object.keys(images).map(filename => filename.split('-')[0])
  ));

  return (
    <div>
      {uniqueNumbers.map(number => (
        <div key={number} style={{ display: 'inline-block', margin: '10px', cursor: 'pointer' }} onClick={() => onFrameSelect(number)}>
          <div style={{ position: 'relative', width: '100px', height: '100px' }}>
            <img src={images[`${number}-Top.png`]} alt={`Top Frame ${number}`} style={{ width: '100%', height: 'auto', position: 'absolute', top: 0 }} />
            <img src={images[`${number}-Bottom.png`]} alt={`Bottom Frame ${number}`} style={{ width: '100%', height: 'auto', position: 'absolute', bottom: 0 }} />
            <img src={images[`${number}-Left.png`]} alt={`Left Frame ${number}`} style={{ height: '100%', width: 'auto', position: 'absolute', left: 0 }} />
            <img src={images[`${number}-Right.png`]} alt={`Right Frame ${number}`} style={{ height: '100%', width: 'auto', position: 'absolute', right: 0 }} />
            <img src={images[`${number}-TopLeftCorner.png`]} alt={`Top Left Corner ${number}`} style={{ width: 'auto', height: '6%', position: 'absolute', top: 0, left: 0 }} />
            <img src={images[`${number}-BottomLeftCorner.png`]} alt={`Bottom Left Corner ${number}`} style={{ width: 'auto', height: '6%', position: 'absolute', bottom: 0, left: 0 }} />
            <img src={images[`${number}-TopRightCorner.png`]} alt={`Top Right Corner ${number}`} style={{ width: 'auto', height: '6%', position: 'absolute', top: 0, right: 0 }} />
            <img src={images[`${number}-BottomRightCorner.png`]} alt={`Bottom Right Corner ${number}`} style={{ width: 'auto', height: '6%', position: 'absolute', bottom: 0, right: 0 }} />
          </div>
          <Typography variant='subtitle2' color={'gray'}>
          {number}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default FrameSelector;
