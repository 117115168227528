import React from 'react';
import './TermsAndConditions.css'; // Assuming you have a CSS file for styles

const PaymentAndShippment = () => {
  return (
    <div className="terms-and-conditions">
      <h1>Payments Policy</h1>

      <section>

        <h2>Payment Policies</h2>

        <p>All orders on magicdecor.com require pre-payment. For bulk orders, please contact us or email us at <a href="mailto:sales@magicdecor.com">sales@magicdecor.com</a> to discuss postpaid options and onboarding procedures.</p>

        <h2>Accepted Payment Methods:</h2>
        <ul>
          {/* List accepted payment methods here */}
        </ul>

        <h2>Are there any hidden charges?</h2>
        <p>No hidden fees are applied on magicdecor.com. The price listed on the product page is the final amount you will pay.</p>

        <h2>Is Cash On Delivery (COD) available?</h2>
        <p>We do not offer Cash On Delivery due to our internal policies.</p>

        <h2>How does store credit work?</h2>
        <p>Store credit can be used for wallpaper orders on magicdecor.com, but it cannot be applied to sample orders.</p>

        <h1>Shipping Policies</h1>

        <h2>Free Shipping</h2>
        <p>We offer free shipping throughout India. All murals are printed on demand and treated as custom orders to ensure the highest quality.</p>

        <h2>Delivery Commitment</h2>
        <p>We aim to deliver your order in excellent condition within the specified timeframe. Orders are shipped seven days a week, excluding Sundays and public holidays, through reputable courier services. In areas where priority courier is unavailable, we use our standard shipping partners.</p>

        <h2>International Shipments</h2>
        <p>International orders are dispatched via FedEx. To check service availability and shipping options, add your product to the cart and proceed to checkout. Estimated shipping times for international orders are subject to carrier feasibility.</p>

        <h2>Import Duty / Customs & Taxes</h2>
        <p>Import duties and other charges may apply and are the responsibility of the customer. We will notify you of any additional charges via email before shipping. Prices on our website include GST but exclude any local taxes that may apply in your region.</p>

        <h2>Need It Fast?</h2>
        <p>For urgent projects, we offer a rush production service. Call us at +91-8046805814 before placing your order to use this service.</p>

        <h2>Delivery Charges for Multiple Units</h2>
        <p>Shipping costs are calculated per product. When ordering multiple products, each product’s shipping charge is added together. The total shipping fee is the sum of individual charges.</p>

        <h2>Delivery Time</h2>
        <p>Orders are processed within three working days or by the specified delivery date. Most orders are delivered within 7 to 8 business days to the address provided at checkout.</p>

        <h2>Damaged Products</h2>
        <p>If your product arrives damaged or if the packaging is compromised, please refuse the package and return it to the delivery person. Notify us immediately at <a href="mailto:support@artwalle.com">support@artwalle.com</a> or contact us here. We will arrange for a replacement at no extra cost. Ensure the original tag and packaging are intact when returning the product.</p>
      </section>

      {/* Other sections */}

    </div>
  );
};

export default PaymentAndShippment;
