import React, { useState, useEffect, useRef } from 'react';
import './ImageCarousel.css';

const ImageCarousel = ({ images, autoPlayInterval = 3000, effect = 'slide', showButtons = true }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const slideRef = useRef(null);

  const totalImages = images.length;
  const extendedImages = [images[totalImages - 1], ...images, images[0]];

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, autoPlayInterval);

    return () => clearInterval(interval);
  }, [autoPlayInterval]);

  useEffect(() => {
    if (effect === 'slide') {
      if (currentImageIndex === 0) {
        setTimeout(() => {
          if (slideRef.current) {
            slideRef.current.style.transition = 'none';
            setCurrentImageIndex(totalImages);
            requestAnimationFrame(() => {
              if (slideRef.current) {
                slideRef.current.style.transition = '';
              }
            });
          }
        }, 300);
      } else if (currentImageIndex === totalImages + 1) {
        setTimeout(() => {
          if (slideRef.current) {
            slideRef.current.style.transition = 'none';
            setCurrentImageIndex(1);
            requestAnimationFrame(() => {
              if (slideRef.current) {
                slideRef.current.style.transition = '';
              }
            });
          }
        }, 300);
      }
    }
  }, [currentImageIndex, totalImages, effect]);

  const nextImage = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentImageIndex((prevIndex) => prevIndex + 1);
  };

  const prevImage = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentImageIndex((prevIndex) => prevIndex - 1);
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const renderImages = () => {
    if (effect === 'slide') {
      return (
        <div
          className="carousel-slide"
          ref={slideRef}
          style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
          onTransitionEnd={handleTransitionEnd}
        >
          {extendedImages.map((image, index) => (
            <img key={index} src={image} alt={`slide-${index}`} className="carousel-image" />
          ))}
        </div>
      );
    } else if (effect === 'fade') {
      return (
        <div className="carousel-fade">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`slide-${index}`}
              className={`carousel-image fade ${index === (currentImageIndex - 1) % images.length ? 'active' : ''}`}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="carousel-container">
      {renderImages()}
      {showButtons && (
        <>
          <button onClick={prevImage} className="carousel-control prev">&#10094;</button>
          <button onClick={nextImage} className="carousel-control next">&#10095;</button>
        </>
      )}
    </div>
  );
};

export default ImageCarousel;
