import React from 'react';
import StarRatingStatic from '../StarRatings/StarRatingStatic';
import ShareAndFavorite from '../Icons/ShareAndFavorites';
import '../ProductDetails/ProductInfo.css';
import '../GlobalStyling/GlobalStyling.css'

const ProductInfo = ({
    name,
    rating,
    reviewCount,
    originalPrice,
    discountedPrice,
    deliveryDate,
    location,
    handleShare,
    handleFavorite
}) => (
    <div className="product-info-container">
        <div className="product-info">
            <h3>{name}</h3>
            <ShareAndFavorite onShare={handleShare} onFavorite={handleFavorite} isFavorite={false} />
        </div>
        <div className="product-actions">
            <StarRatingStatic value={rating} reviewCount={reviewCount} />
            {/*
                <h3><s>₹ {originalPrice}</s> <strong>₹ {discountedPrice}/sq.ft.</strong></h3> 
                */}
            <h4><strong>₹ {discountedPrice}/sq.ft.</strong></h4>
            <h5>Delivery by <strong>{deliveryDate}</strong> to <strong>{location}</strong></h5>
        </div>
    </div>
);

export default ProductInfo;
