import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, googleProvider } from '../../Functions/firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import './Login.css'; // Import the CSS file
import googleLogo from './Google_GoogleLogo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate(); // Use useNavigate hook for navigation

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log('Login successful:', userCredential);
        const userData = {
          email: userCredential.user.email,
          uid: userCredential.user.uid,
          mobile: '', // You might not have phoneNumber for email/password login
          firstName: '', // You might not have displayName for email/password login
          lastName: '', // You might not have displayName for email/password login
          registrationDate: new Date().toISOString()
        };
        localStorage.setItem('userData', JSON.stringify(userData));
        navigate('/');
        window.location.reload(); // Reload the page after navigating to '/'
      })
      .catch((error) => {
        console.error('Error logging in:', error);
        if (error.code === 'auth/user-not-found') {
          setLoginError('User not registered');
        } else if (error.code === 'auth/wrong-password') {
          setLoginError('Incorrect password');
        } else {
          setLoginError('Error logging in');
        }
      });
  };

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        console.log('Google sign-in successful:', result);
        const userData = {
          email: result.user.email,
          uid: result.user.uid,
          mobile: result.user.phoneNumber || '',
          firstName: result.user.displayName ? result.user.displayName.split(' ')[0] : '',
          lastName: result.user.displayName ? result.user.displayName.split(' ')[1] : '',
          registrationDate: new Date().toISOString()
        };
        localStorage.setItem('userData', JSON.stringify(userData));
        navigate('/');
        window.location.reload(); // Reload the page after navigating to '/'
      })
      .catch((error) => {
        console.error('Error during Google sign-in:', error);
      });
  };

  const handleForgotPassword = () => {
    // Navigate to forgot password page
    navigate('/forgot-password');
  };

  const handleRegister = () => {
    // Navigate to register page
    navigate('/register');
  };

  return (
    <div className="login-main-container">
      <div className="container">
        <div className="header">
          <h2 className="headerText">Login</h2>
          <button className="closeButton">&times;</button>
        </div>
        <div className="formGroup">
          <label className="label">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
        </div>
        <div className="formGroup">
          <label className="label">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
        </div>
        {loginError && <p className="error">{loginError}</p>}
        <button onClick={handleLogin} className="loginButton">Login</button>
        <p className="orText">or login using</p>
        <button onClick={handleGoogleSignIn} className="googleButton">
          <img src={googleLogo} alt="Google" className="googleIcon" />
          Google
        </button>
        <div className="options">
          <button onClick={handleForgotPassword} className="optionButton">Forgot Password?</button>
          <button onClick={handleRegister} className="optionButton">Register</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
