import React from 'react'
import ProductListMasonry from '../Assets/PriductList/ProductListMasonry'
import SearchPage from '../Assets/SearchBar/SearchPage'
import SubLandingPage from '../Assets/SubLandingPage/SubLandingPage'

const Wallpapers = () => {
    return (
        <div>
            <SubLandingPage />
            <SearchPage />
        </div>
    )
}

export default Wallpapers
