import React from 'react'
import ProductListMasonry from '../../Assets/PriductList/ProductListMasonry'

const ProductList = () => {
  return (
   <>
   <ProductListMasonry />
   
   {/*
    <ProductListClassic />
      
      */}
</>
  )
}

export default ProductList
