import React from 'react'
import Canvas from './Assets/Customization/Canvas/Canvas'

const CustomisedCanvasPrinting = () => {
  return (
<Canvas />
  )
}

export default CustomisedCanvasPrinting
