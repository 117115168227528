import React from 'react';
import { Box } from '@mui/material';

const BackgroundMountView = ({ imageUrl }) => {
  return (
    <Box 
    
    position={'absolute'}
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    }}
    
    display="flex" justifyContent="center">
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Selected"
          style={{height:'100%', width:'100%', objectFit:'cover'}}

        />
      ) : (
        <span style={{ objectFit: 'cover' }}
         
        ></span>
      )}
    </Box>
  );
};

export default BackgroundMountView;
