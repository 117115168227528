import React from 'react'
import ProductList from '../Components/Products/ProductList';
import LandingPage from '../Components/LandingPage/LandingPage';
import ProductListHScroll from '../Assets/PriductList/ProductListHScroll';
import InstallInfo from '../Assets/Information/InstallInfo';
import ProductAdvertise from '../Components/ProductAdvertise/ProductAdvertise';
import FrequentlyAskedQuestions from '../Components/FrequentlyAskedQuestions/FrequentlyAskedQuestions';

const HomePage = () => {

 



  return (

<>
      <LandingPage />
      <ProductList />
      <InstallInfo />
      <ProductListHScroll />
      <ProductAdvertise />
      <FrequentlyAskedQuestions/>

      
      
      {/*
        <UserFormLeads />
        <LandingPageCounter />
      <ProductDetails />
      <Register />
      <ProductOffers />
      <PhotoFrame />
      <Canvas /> 
      <CustomerReview />
      */}

</>

  )
}

export default HomePage
