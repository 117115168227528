import React from 'react';
import './LandingPage.css';
import '../../Assets/GlobalStyling/GlobalStyling.css'

import Button2 from '../../Assets/Buttons/Button2';
import SearchBar from '../../Assets/SearchBar/SearchBar';
import ImageCarousel from '../../Assets/Carousel/ImageCarousel';

const LandingPage = () => {
  const handleButtonClick = () => {
    console.log('Button clicked!');
  };

  // Import images for the landing page animation boxes

  const sliderImage1='https://artwalle.com/Images/LandingPageAnimationImage/sliderImage1.webp'
  const sliderImage2='https://artwalle.com/Images/LandingPageAnimationImage/sliderImage2.webp'
  const sliderImage3='https://artwalle.com/Images/LandingPageAnimationImage/sliderImage3.webp'
  const sliderImage4='https://artwalle.com/Images/LandingPageAnimationImage/sliderImage4.webp'
  const sliderImage5='https://artwalle.com/Images/LandingPageAnimationImage/sliderImage5.webp'

  const images = [sliderImage1, sliderImage2, sliderImage3, sliderImage4, sliderImage5];

  const image1='https://artwalle.com/Images/LandingPageAnimationImage/LandingPageImage1.webp'
  const image2='https://artwalle.com/Images/LandingPageAnimationImage/LandingPageImage2.webp'
  const image3='https://artwalle.com/Images/LandingPageAnimationImage/LandingPageImage3.webp'

  return (
    <div className="root-landing-page">
      <div className="sliderWrapper">
        <ImageCarousel
          images={images}
          effect='fade'
          autoPlayInterval={4000}
          showButtons={false}
        />
      </div>

      <div className="landing-page-containt-container">
        <div className="leftSection">
          <h1>Artwalle</h1>
          <p className="body1">Where Creativity Meets Home Decor</p>
          <div className="searchBarWrapper">
            <SearchBar />
          </div>
        </div>
        <div className="rightSection">          <div className="animatedBox">
            <img src={image1} alt="canvas" className="imgResponsive" />
            <div className="animation-button">
              <Button2 onClick={handleButtonClick}>Canvas</Button2>
            </div>
          </div>
          <div className="animatedBox" style={{ animationDelay: '.5s' }}>
            <img src={image2} alt="wallpaper" className="imgResponsive" />
            <div className="animation-button">
              <Button2>Wallpaper</Button2>
            </div>
          </div>
          <div className="animatedBox" style={{ animationDelay: '1s' }}>
            <img src={image3} alt="printing" className="imgResponsive" />
            <div className="animation-button">
              <Button2>Printing</Button2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
