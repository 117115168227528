import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Grid, Box, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import ProductInfo from './ProductInfo';
import ProductCustomization, { materials } from './ProductCustomization';
import CustomerReview from '../Review/CustomerReview';
import '../GlobalStyling/GlobalStyling.css'
import productsData from '../../Json/Product.json'; // Import JSON data


const ProductDetailsPremiunWallpapers = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // Initially no image selected

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://api.artwalle.pattanaikdatapoint.com/productlist/${id}`);
        const fetchedProduct = response.data;
        setProduct(fetchedProduct);
      } catch (error) {
        setError('Error fetching product details');
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id]);

  // useEffect(() => {
  //   const fetchProduct = async () => {
  //     try {
  //       // Find the product by id from the JSON data
  //       const fetchedProduct = productsData.find(p => p.id.toString() === id);
  //       if (fetchedProduct) {
  //         setProduct(fetchedProduct);
  //       } else {
  //         setError('Product not found');
  //       }
  //     } catch (error) {
  //       setError('Error fetching product details');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchProduct();
  // }, [id]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!product) return <Typography>No product found</Typography>;

  const {
    product_name, product_price, product_spec1, product_spec2, product_spec3,
    product_spec4, product_spec5, product_spec6, product_spec7, product_spec8,
    product_img1, product_img2, product_img3, product_img4, product_img5
  } = product;

  const specifications = [
    product_spec1, product_spec2, product_spec3,
    product_spec4, product_spec5, product_spec6,
    product_spec7, product_spec8
  ].filter(Boolean);

  const handleShare = () => {
    alert('Share button clicked');
  };

  const handleFavorite = (isFavorite) => {
    alert(`Favorite status changed: ${isFavorite}`);
  };

  // const formatImagePath = (path) => {
  //   if (!path) return 'https://via.placeholder.com/300'; // Fallback image
  //   const filename = path.split('\\').pop(); // Extract the filename from the absolute path
  //   return `${process.env.PUBLIC_URL}/ProductImages/${filename}`;
  // };

  const formatImagePath = (path) => {
    if (!path) return 'https://artwalle.com/ProductImages/default-image.jpeg'; // Fallback image
    const filename = path.split('\\').pop(); // Extract the filename from the absolute path
    return `https://artwalle.com/ProductImages/${filename}`;
};

  const productImages = [
    product_img1, product_img2, product_img3,
    product_img4, product_img5
  ].filter(Boolean).map(formatImagePath);

  const firstImage = productImages[0];

  return (
    <>
    <Grid container lg={10} mt={15}>
      <Box sx={{ padding: '5px', display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <ProductImages images={productImages} selectedImage={selectedImage} onImageClick={handleImageClick} initialImage={firstImage} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProductInfo
              name={product_name}
              rating={4} // Placeholder value; update with actual data
              reviewCount={10} // Placeholder value; update with actual data
              originalPrice={product_price}
              discountedPrice={product_price}
              deliveryDate={"2023-12-31"} // Placeholder value; update with actual data
              location={"Location"} // Placeholder value; update with actual data
              handleShare={handleShare}
              handleFavorite={handleFavorite}
            />
            <ProductCustomization
            materials={materials}
            defaultMaterial={materials[0].value}
            />
            <ProductSpecifications specifications={specifications} />
          </Grid>
        </Grid>
      </Box>
    </Grid>

    {/*
      <CustomerReview/>
      */}

    </>
  );
};

const ProductImages = ({ images, selectedImage, onImageClick, initialImage }) => (
  <Grid container lg={12} justifyContent={'center'}>
    <Box sx={{ textAlign: 'center' }}>
      {selectedImage ? (
        <img src={selectedImage} alt="Product" style={{ width: '100%' }} />
      ) : (
        <img src={initialImage} alt="Product" style={{ width: '100%' }} />
      )}
    </Box>
    <Grid item mt={1} display={'flex'} justifyContent={'space-between'}>
      {images.map((image, index) => (
        <Grid key={index} lg={2.2} onClick={() => onImageClick(image)}>
          <img src={image} alt="Product thumbnail" style={{ width: '100%', cursor: 'pointer' }} />
        </Grid>
      ))}
    </Grid>
  </Grid>
);

// const TotalAmount = ({ discountedPrice }) => (
//   <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//     <Typography variant="h6">Total Amount: ₹ {discountedPrice}/sq.ft.</Typography>
//     <Button variant="contained" color="primary">Proceed to Check-Out</Button>
//   </Box>
// );

const ProductSpecifications = ({ specifications }) => (
  <Box>
    <Typography variant="h6">Product Specification</Typography>
    <Typography variant="body2">
      {specifications.map((spec, index) => (
        <div key={index}>- {spec}<br /></div>
      ))}
    </Typography>
  </Box>
);

export default ProductDetailsPremiunWallpapers;
