import { Hidden } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

const PopupErrorNotification = ({ message, onClose }) => {
    const notificationRef = useRef(null);

    // Initial slide-in animation when component mounts
    useEffect(() => {
        notificationRef.current.style.left = '20px'; // Slide in
        notificationRef.current.style.opacity = 1; // Fade in
    }, []);

    // Slide out and fade out before unmount
    useEffect(() => {
        const timer = setTimeout(() => {
            notificationRef.current.style.left = '-250px'; // Slide out to the left
            notificationRef.current.style.opacity = 0; // Fade out
            setTimeout(onClose, 300); // Close popup after slide-out/fade-out transition (300ms)
        }, 5000); // Close popup automatically after 5 seconds

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div
            className="popup-error-notification"
            ref={notificationRef}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                bottom: '50px',
                left: '-250px', // Start off-screen left
                backgroundColor: '#ef233c',
                height: 'auto',
                width: 'auto',
                borderRadius: '10px',
                boxShadow: '0px 0px 4px rgba(87, 87, 87, 0.8)',
                padding: '20px ',
                color: '#edf6f9',
                cursor: 'pointer',
                opacity: 0, // Initially hidden
                transition: 'left 0.3s ease, opacity 0.3s ease', // Slide-in/slide-out and fade-in/fade-out transitions
                overflow:'hidden'
            }}
            onClick={onClose}
        >
            {message}
        </div>
    );
};

export default PopupErrorNotification;
