import React from 'react';
import './Cart.css';
import Button2 from '../Buttons/Button2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const Cart = ({ product }) => {
  return (
    <>
    <div className="cart-item">

      <div className="product-image">
        {/* Placeholder for product image */}
      </div>

      <div className="product-details">
        <h3>{product.name}</h3>
        <p>Size: {product.printArea} sq.ft.</p>
        <p>Dimension: {product.dimension}</p>
        <p>Material: {product.material}</p>
        <p>Quantity: {product.quantity}</p>
      </div>

      <div className="product-price-action-container">
        <div className="product-price">
          <p>₹ {product.price}/sq.ft.</p>
        </div>

        <div className="product-action">
          <p>Remove Item</p>
          <DeleteIcon className='product-action-icon-delete' onClick={() => console.log('Delete', product.id)} />
        </div>

      </div>

    </div>
    </>
  );
};

export default Cart;
