import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css'; // Import the CSS file

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const searchButton = document.querySelector('.search-button');
        const searchInput = document.querySelector('.search-input');

        const handleMouseOver = () => {
            searchInput.style.border = '2px solid rgb(255, 55, 28)';
            searchInput.style.color = '#ffffff';
        };

        const handleMouseOut = () => {
            searchInput.style.border = '2px solid rgb(255, 159, 28)';
            searchInput.style.color = '';
        };

        searchButton.addEventListener('mouseover', handleMouseOver);
        searchButton.addEventListener('mouseout', handleMouseOut);

        return () => {
            searchButton.removeEventListener('mouseover', handleMouseOver);
            searchButton.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    const handleSearch = async () => {
        setLoading(true);
        navigate(`/Searched?query=${searchTerm}`);
        setLoading(false);
    };

    return (
        <div className="styled-search-bar">
            <div className="search-input-container">
                <div className="autocomplete-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="What are you looking for..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="search-button" onClick={handleSearch}>
                        {loading ? 'Loading...' : 'Search'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
