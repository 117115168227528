import React from 'react';
import './ProductAdvertise.css';
import ImageCarousel from '../../Assets/Carousel/ImageCarousel';
import image1 from './6225108.jpg';
import image2 from './6225132.jpg';
import image3 from './6225232.jpg';
import blankwall from './BlankWall.png';
import Button2 from '../../Assets/Buttons/Button2';


const ProductAdvertise = () => {
    const images = [
        image1,
        image2,
        image3,
        image1,
        image2,
        image3,
        // Add more image paths as needed
    ];

    return (
        <div className='ProductAdvertise'>
            <div className='ProductAdvertise-wrapper'>
                <div className='ImageCarousel-wrapper'>
                    <img className='ProductAdvertise-blankWall' src={blankwall} alt="" />
                    <div className="add-carousel">
                        <ImageCarousel
                            images={images}
                            effect='fade'
                            autoPlayInterval={2000}
                            showButtons={false}
                        />
                    </div>
                </div>

                <div className="ProductAdvertise-action">
                    <h3>Customized Wallpapers for Your Signature Style !</h3>
                    <p>You are the king of your own design</p>
                    <div className="ProductAdvertise-action-button">
                        <Button2> Upload your design</Button2>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ProductAdvertise;
