import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { ShoppingCart, Favorite, AccountCircle } from '@material-ui/icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './UpperNavbar.css';

function UpperNavbar({ logo, menuItems, isLoggedIn, cartCount, favoriteCount, userEmail }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const accountMenuRef = useRef(null);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);

    const handleAccountMenuToggle = () => {
        setAccountMenuOpen(!accountMenuOpen);
    };

    const handleMenuClick = (route) => {
        if (route === '/') {
            // Clear local storage
            localStorage.clear();
            // Reload the page
            window.location.reload();
        } else {
            navigate(route);
        }
    };

    const handleClickOutside = (event) => {
        if (accountMenuRef.current && !accountMenuRef.current.contains(event.target)) {
            setAccountMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;
            setVisible(visible);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const renderMenuItems = (menuItems) => {
        return menuItems.map((item, index) => (
            <div
                key={index}
                className={`menu-item ${location.pathname.startsWith(item.route) ? 'active' : ''}`}
                onClick={() => !item.submenu && handleMenuClick(item.route)}
            >
                {item.label}
                {item.submenu && item.submenu.length > 0 && (
                    <span className="arrow">
                        <ArrowDropDownIcon />
                    </span>
                )}
                <div className="underline" />
                {item.submenu && item.submenu.length > 0 && (
                    <div className="submenu">
                        {item.submenu.map((subItem, subIndex) => (
                            <div
                                key={subIndex}
                                className="submenu-item"
                                onClick={() => handleMenuClick(subItem.route)}
                            >
                                {subItem.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ));
    };

    return (
        <div className={`navbar ${visible ? 'visible' : 'hidden'}`}>
            <div className="navbar-content">
                <div className="logo" onClick={() => navigate('/')}>
                    <img src={logo} alt="Logo" />
                </div>
                <div className="menu">
                    {renderMenuItems(menuItems)}
                </div>
                <div className="actions">
                    <div className="cart">
                        <div className="icon" onClick={() => navigate('/favorites')}>
                            <Badge badgeContent={favoriteCount} color="error">
                                <Favorite style={{ color: 'white' }} />
                            </Badge>
                        </div>
                        <div className="icon" onClick={() => navigate('/cart')}>
                            <Badge badgeContent={cartCount} color="error">
                                <ShoppingCart style={{ color: 'white' }} />
                            </Badge>
                        </div>
                    </div>

                    <div className="icon account-icon" onClick={handleAccountMenuToggle} ref={accountMenuRef}>
                        <div className="account-info">
                        <AccountCircle style={{ color: 'white' }} />
                        {userEmail}
                        </div>
                        {accountMenuOpen && (
                            <div className="account-submenu">
                            {isLoggedIn ? (
                                <>
                                        <div className="submenu-item" onClick={() => handleMenuClick('/orders')}>Orders</div>
                                        <div className="submenu-item" onClick={() => handleMenuClick('/profile')}>Profile</div>
                                        <div className="submenu-item" onClick={() => handleMenuClick('/')}>Log out</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="submenu-item" onClick={() => handleMenuClick('/login')}>Login</div>
                                        <div className="submenu-item" onClick={() => handleMenuClick('/register')}>Register</div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpperNavbar;
