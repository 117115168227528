import React from 'react';
import '../../../Customization/PhotoFrame/PhotoFrame.css';
import { Grid } from '@mui/material';
import { Margin } from '@mui/icons-material';

const Frame = ({ frames }) => {
  if (!frames || Object.keys(frames).length === 0) {
    return null;
  }

  return (
    <Grid
      className="photoFrame"
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'absolute',
        overflow: 'hidden',
        boxShadow:'0px 0px 15px rgb(0,0,0,.4)'
      }}
    >
      <div>
        <div className="frame top" >
          <img src={frames['Top']} alt="Top Frame"  />
        </div>
        <div className="frame bottom">
          <img src={frames['Bottom']} alt="Bottom Frame" />
        </div>
        <div className="frame left">
          <img src={frames['Left']} alt="Left Frame" />
        </div>
        <div className="frame right">
          <img src={frames['Right']} alt="Right Frame" />
        </div>
        <div className="corner top-left-corner">
          <img src={frames['TopLeftCorner']} alt="Top Left Corner" />
        </div>
        <div className="corner bottom-left-corner">
          <img src={frames['BottomLeftCorner']} alt="Bottom Left Corner" />
        </div>
        <div className="corner top-right-corner">
          <img src={frames['TopRightCorner']} alt="Top Right Corner" />
        </div>
        <div className="corner bottom-right-corner">
          <img src={frames['BottomRightCorner']} alt="Bottom Right Corner" />
        </div>
      </div>
    </Grid>
  );
};

export default Frame;
