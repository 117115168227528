import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Grid, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const AdminProductDetails = ({ product, onUpdate }) => {
  const classes = useStyles();
  const [editedProduct, setEditedProduct] = useState({ ...product });
  const [imageFiles, setImageFiles] = useState(Array(5).fill(null)); // State to hold uploaded image files
  const [requestCount, setRequestCount] = useState(0); // Counter to track the number of requests sent

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    const updatedImageFiles = [...imageFiles];
    updatedImageFiles[index] = file;
    setImageFiles(updatedImageFiles);

    // Update edited product with image URL or file object based on your requirement
    setEditedProduct(prevState => ({
      ...prevState,
      [`product_img${index + 1}`]: file // Assuming you want to store file object
    }));
  };

  const handleUpdate = async () => {
    try {
      setRequestCount(prevCount => prevCount + 1); // Increment the request count
      const formData = new FormData();

      // Append product data to FormData object
      Object.entries(editedProduct).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Append image files to FormData object
      imageFiles.forEach((file, index) => {
        if (file) {
          formData.append(`product_img${index + 1}`, file);
        }
      });

      console.log(imageFiles);
      console.log("Number of images sent:", imageFiles.filter(file => file !== null).length);
      console.log("Request count:", requestCount); // Log the request count

      console.log("FormData content:", formData);
      console.log("Edited product:", editedProduct);
console.log("Image files:", imageFiles);


      // Send FormData to backend server
      const response = await fetch('https://api.artwalle.pattanaikdatapoint.com/ProductUpdate', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        console.log('Product details saved successfully');
        // Optionally, you can reset the form or show a success message here
      } else {
        console.error('Failed to save product details:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <Grid container spacing={2} alignItems="center" style={{marginTop:'150px'}}>
      <Grid item lg={3}>
        <TextField
          fullWidth
          label="Product Name"
          name="product_name"
          value={editedProduct.product_name}
          onChange={handleFieldChange}
        />
      </Grid>

      <Grid item lg={3}>
        <Select
          fullWidth
          label="Category"
          name="category"
          value={editedProduct.category}
          onChange={handleFieldChange}
        >
          <MenuItem value="Premium Wallpapers">Premium Wallpapers</MenuItem>
          <MenuItem value="Category2">Category2</MenuItem>
          {/* Add more categories here */}
        </Select>
      </Grid>

      <Grid item lg={3}>
        <Select
          fullWidth
          label="Subcategory"
          name="product_subcategory"
          value={editedProduct.product_subcategory}
          onChange={handleFieldChange}
        >
          <MenuItem value="Subcategory1">Subcategory1</MenuItem>
          <MenuItem value="Subcategory2">Subcategory2</MenuItem>
          {/* Add more subcategories here */}
        </Select>
      </Grid>

      <Grid item lg={3}>
        <TextField
          fullWidth
          label="Price"
          name="product_price"
          type="number"
          value={editedProduct.product_price}
          onChange={handleFieldChange}
        />
      </Grid>

      {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
        <Grid item lg={6} key={index}>
          <TextField
            fullWidth
            label={`Spec ${index}`}
            name={`product_spec${index}`}
            value={editedProduct[`product_spec${index}`]}
            onChange={handleFieldChange}
          />
        </Grid>
      ))}

      {[...Array(5)].map((_, index) => (
        <Grid item key={index} lg={1}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id={`image-upload-${index}`}
            type="file"
            name="product_img" // Change the field name to 'product_img'
            onChange={(e) => handleImageChange(e, index)}
          />

          <label htmlFor={`image-upload-${index}`}>
            <Button variant="outlined" component="span">
              Upload Image {index + 1}
            </Button>
          </label>
        </Grid>
      ))}

      <Grid item lg={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdminProductDetails;
