import React, { useEffect, useRef } from 'react';
import './InstallInfo.css'; // Import the external CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectGroup, faGift, faFingerprint, faTruckFast, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';

const steps = [
  {
    icon: <FontAwesomeIcon icon={faObjectGroup} />,
    altText: 'Choose a Design',
    stepNumber: 'STEP 1',
    title: 'Choose a Design',
    description: 'Browse through our design catalogue to choose a design that aligns with your home decor.',
  },
  {
    icon: <FontAwesomeIcon icon={faGift} />,
    altText: 'The Order',
    stepNumber: 'STEP 2',
    title: 'The Order',
    description: 'Place your order by putting in your measurements and completing the checkout process.',
  },
  {
    icon: <FontAwesomeIcon icon={faFingerprint} />,
    altText: 'Digital Proof',
    stepNumber: 'STEP 3',
    title: 'Digital Proof',
    description: 'Our designer experts will then get your design tweaked as per your individual preferences.',
  },
  {
    icon: <FontAwesomeIcon icon={faTruckFast} />,
    altText: 'Production & Shipping',
    stepNumber: 'STEP 4',
    title: 'Production & Shipping',
    description: 'Your order is then manufactured and dispatched within 48 hours of your design proof approval.',
  },
  {
    icon: <FontAwesomeIcon icon={faPersonChalkboard} />,
    altText: 'Installation',
    stepNumber: 'STEP 5',
    title: 'Installation',
    description: 'Easily get your wallpaper installed with our wide installation network covering 100+ cities.',
  },
];

const InstallInfo = () => {
  const headingRef = useRef(null);
  const paragraphRef = useRef(null);
  const stepRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === headingRef.current) {
              entry.target.classList.add('slide-in');
            } else if (entry.target === paragraphRef.current) {
              setTimeout(() => {
                entry.target.classList.add('slide-in');
              }, 1800); // Delay for the paragraph
            } else {
              const index = stepRefs.current.indexOf(entry.target);
              if (index !== -1) {
                setTimeout(() => {
                  entry.target.classList.add('step-visible');
                }, index * 500); // Delay based on step index
              }
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    if (headingRef.current) observer.observe(headingRef.current);
    if (paragraphRef.current) observer.observe(paragraphRef.current);
    stepRefs.current.forEach((step) => {
      if (step) observer.observe(step);
    });

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (paragraphRef.current) observer.unobserve(paragraphRef.current);
      stepRefs.current.forEach((step) => {
        if (step) observer.unobserve(step);
      });
    };
  }, []);

  return (
    <div className="install-info-container">
      <h2 ref={headingRef}>Breathtaking Designs. 3 Years Warranty on Color Fastness.</h2>
      <p className='install-info-container-p' ref={paragraphRef}>Ordering wallpapers has never been easier.</p>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div
            key={index}
            className="step"
            ref={(el) => (stepRefs.current[index] = el)}
          >
            <div className='info-icons'>{step.icon}</div>
            <h3>{step.stepNumber}</h3>
            <h4>{step.title}</h4>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstallInfo;
