import React, { useState, useRef } from 'react';
import { Box, Slider, Typography, TextField, Grid, Button, Collapse, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import PictureUploader from '../CostomizationComponents/PictureUpload/PictureUploader';
import DraggableImage from '../CostomizationComponents/DragableImage/DraggableImage';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import GridBackGround from '../Canvas/GridBackGround.jpg'
const defaultSizes = [
  { label: '8x10', width: 8, height: 10, price: 199 },
  { label: '9x12', width: 9, height: 12, price: 299 },
  { label: '11x14', width: 11, height: 14, price: 399 },
  { label: '12x12', width: 12, height: 12, price: 499 },
  { label: '12x13', width: 12, height: 13, price: 599 },
  { label: '16x20', width: 16, height: 20, price: 699 },
  { label: '18x24', width: 18, height: 24, price: 799 },
  { label: '24x24', width: 24, height: 24, price: 899 },
  { label: '24x30', width: 24, height: 30, price: 999 },
  { label: 'A0', width: 33.1, height: 46.8, price: 1099 },
  { label: 'A1', width: 23.4, height: 33.1, price: 1199 },
  { label: 'A2', width: 16.5, height: 23.4, price: 1299 },
  { label: 'A3', width: 11.7, height: 16.5, price: 1399 },
  { label: 'A4', width: 8.3, height: 11.7, price: 1499 },
  { label: 'Custom', width: null, height: null, price: null }
];

const steps = ['Upload Picture', 'Select Size', 'Select Side Size', 'Priceed with Order'];

const Canvas = () => {
  const [width, setWidth] = useState(12); // default width in inches
  const [height, setHeight] = useState(12); // default height in inches
  const [sideSize, setSideSize] = useState(1.5); // Initial size for side divs in inches
  const [picture, setPicture] = useState(null);
  const [centralImagePosition, setCentralImagePosition] = useState({ x: 0, y: 0 });
  const [centralImageScale, setCentralImageScale] = useState(1);
  const [customSizeSelected, setCustomSizeSelected] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const centerRef = useRef(null);


  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleWidthChange = (event, newValue) => setWidth(newValue);
  const handleHeightChange = (event, newValue) => setHeight(newValue);
  const handleManualWidthChange = (event) => setWidth(event.target.value ? parseInt(event.target.value, 10) : '');
  const handleManualHeightChange = (event) => setHeight(event.target.value ? parseInt(event.target.value, 10) : '');
  const handleSideSizeChange = (event, newValue) => setSideSize(newValue);
  const handleManualSideSizeChange = (event) => setSideSize(event.target.value ? parseFloat(event.target.value) : '');
  const handleCentralImageDrag = (position) => setCentralImagePosition(position);
  const handleCentralImageZoom = (scale) => setCentralImageScale(scale);

  const handleDefaultSizeClick = (size) => {
    if (size.label === 'Custom') {
      setCustomSizeSelected(true);
    } else {
      setWidth(size.width);
      setHeight(size.height);
      setCustomSizeSelected(false);
    }
  };

  const maxDimension = 60; // Maximum dimension for display
  const aspectRatio = width / height;
  const displayedWidth = width > height ? maxDimension : maxDimension * aspectRatio;
  const displayedHeight = height > width ? maxDimension : maxDimension / aspectRatio;

  return (
    <Grid container lg={12} backgroundColor="rgb(13, 59, 102, .2)" justifyContent={'center'} height={'100vh'}>
      <Grid
        item
        position="absolute"
        height="100%"
        overflow="hidden"
        lg={12}
        sx={{
          backgroundColor: 'rgba(244, 211, 94, 1)', // Background color with 20% opacity
          opacity: 0.2, // Opacity for the entire container
          '& img': {
            width: '100%', // Make the image take up the full width of the container
            height: 'auto', // Maintain aspect ratio
            objectFit: 'contain', // Fit the entire image within the container without stretching or squishing
            opacity: 0.8, // Opacity for the entire container, including the background image

          },
        }}
      >
        <img src={GridBackGround} alt="" />
      </Grid>

      <Grid container lg={12} justifyContent={'center'} alignItems={'center'} p={5}>
        <Grid item lg={6}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (

              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {index === 0 && (
                    <PictureUploader picture={picture} setPicture={setPicture} />
                  )}
                  {index === 1 && (
                    <Grid container lg={10} display='flex' flexWrap={'wrap'} justifyContent={'flex-start'}>
                      {/*
                        <Typography variant="h6" gutterBottom>
                          Select Size:
                        </Typography>
                        */}
                      {defaultSizes.map((size) => (
                        <Button
                          key={size.label}
                          variant="contained"
                          onClick={() => handleDefaultSizeClick(size)}
                          style={{
                            margin: '5px',
                            width: '80px',
                            backgroundColor: size.label === 'Custom' ? (width === null && height === null ? 'rgb(249, 87, 56)' : 'rgb(249, 87, 56)') : (width === size.width && height === size.height ? 'rgb(13, 59, 102, .8)' : 'inherit'),
                            border: '2px solid rgb(13, 59, 102, .6)',
                            color: size.label === 'Custom' ? 'white' : (width === size.width && height === size.height ? 'white' : 'rgb(13, 59, 102, .8)')
                          }}
                        >
                          {size.label}
                          <br />
                          {size.price !== null ? `₹${size.price}` : ''}
                        </Button>
                      ))}

                      <Grid container lg={12}>

                        <Collapse in={customSizeSelected}>

                          <Grid item lg={12} display='flex' justifyContent={'space-between'} alignItems={'center'}>
                            {/*Width (in inches)*/}
                            <Slider
                              value={width}
                              min={8}
                              max={60}
                              onChange={handleWidthChange}
                              aria-labelledby="continuous-slider-width"
                              style={{ margin: '20px 10px 10px 10px', width: 500 }}
                            />
                            <TextField
                              label='Width (in inches)'
                              type="number"
                              value={width}
                              onChange={handleManualWidthChange}
                              inputProps={{ min: 8, max: 60 }}
                              style={{ margin: '20px 10px 10px 10px', width: 155 }}

                            />
                          </Grid>

                          <Grid item lg={12} display='flex' justifyContent={'space-between'} alignItems={'center'}>
                            {/*Height (in inches)*/}
                            <Slider
                              label='test'
                              value={height}
                              min={8}
                              max={60}
                              onChange={handleHeightChange}
                              aria-labelledby="continuous-slider-height"
                              style={{ margin: '20px 10px 10px 10px', width: 500 }}
                            />
                            <TextField
                              label='Height (in inches)'
                              type="number"
                              value={height}
                              onChange={handleManualHeightChange}
                              inputProps={{ min: 8, max: 60 }}
                              style={{ margin: '20px 10px 10px 10px', width: 155 }}
                            />
                          </Grid>

                        </Collapse>
                      </Grid>
                    </Grid>)}
                  {index === 2 && (
                    <Grid display="flex" alignItems="center" mt={2} lg={10}>
                      {/*
                        <Typography style={{ width: '200px' }}>Side Size (in inches):</Typography>
                        */}
                      <Slider
                        value={sideSize}
                        min={0}
                        max={1.5}
                        step={0.5}
                        onChange={handleSideSizeChange}
                        aria-labelledby="continuous-slider-side-size"
                        style={{ width: 200, marginLeft: 20, marginRight: 20 }}
                      />
                      <TextField
                        type="number"
                        value={sideSize}
                        onChange={handleManualSideSizeChange}
                        inputProps={{ min: 0, max: 1.5, step: 0.5 }}
                        style={{ width: 120 }}
                      />
                    </Grid>
                  )}
                  {index === 3 && (
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Add to Cart
                    </Button>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                      {index < steps.length - 1 && (
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>



        <Box
          position="relative"
          width={`${displayedWidth * 10}px`} // Convert inches to pixels for display
          height={`${displayedHeight * 10}px`} // Convert inches to pixels for display
          style={{ boxSizing: 'border-box' }}
        >
          {/* Image Div */}
          <Box
            ref={centerRef}
            position="absolute"
            top={`${sideSize * 10}px`}
            left={`${sideSize * 10}px`}
            right={`${sideSize * 10}px`}
            bottom={`${sideSize * 10}px`}
            display='flex'
            justifyContent='center'
            overflow={'hidden'}
            style={{ boxSizing: 'border-box', border: '2px dotted rgb(13, 59, 102, .6)' }}
            boxShadow={4}
            backgroundColor='#d8e2dc'
          >
            {picture ? (
              <DraggableImage
                src={picture}
                isCentral={true}
                position={centralImagePosition}
                scale={centralImageScale}
                onDrag={handleCentralImageDrag}
                onZoom={handleCentralImageZoom}
              />
            ) : (
              <Grid style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }} gap={1}>
                <CloudDownloadRoundedIcon style={{ fontSize: '60px', color: 'rgb(13, 59, 102, .6)' }} />
                <Typography textAlign={'center'} justifySelf={'center'} fontWeight={600} color={'rgb(13, 59, 102, .6)'} variant='h5'>Upload a Picture to display</Typography>

              </Grid>
            )}
          </Box>

          {/* Top Div */}
          <Box
            position="absolute"
            top="-40px"
            left={`${sideSize * 10}px`}
            right={`${sideSize * 10}px`}
            height={`${sideSize * 30}px`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow={4}
            style={{ boxSizing: 'border-box', border: '2px dotted rgb(13, 59, 102, .6)' }}
            backgroundColor='#d8e2dc'

          >
            {picture && (
              <DraggableImage
                src={picture}
                isCentral={false}
                position={centralImagePosition}
                scale={centralImageScale}
              />
            )}
          </Box>

          {/* Top Div Width Marker */}
          <Box
            position="absolute"
            top="-80px"
            left={`${sideSize * 10}px`}
            right={`${sideSize * 10}px`}
            height={'30px'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              boxSizing: 'border-box',
              borderTop: '2px solid rgb(13, 59, 102, .3)',
              borderLeft: '2px solid rgb(13, 59, 102, .3)',
              borderRight: '2px solid rgb(13, 59, 102, .3)'
            }}
          >
            <Typography
              backgroundColor='rgb(238, 150, 75)'
              width={'100px'}
              position={'absolute'}
              mt={-4}
              textAlign={'center'}
              borderRadius={50}
              color={'white'}
            >{width} inches</Typography>
          </Box>

          {/* Bottom Div */}
          <Box
            position="absolute"
            bottom="-40px"
            left={`${sideSize * 10}px`}
            right={`${sideSize * 10}px`}
            height={`${sideSize * 30}px`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ boxSizing: 'border-box', border: '2px dotted rgb(13, 59, 102, .6)' }}
            boxShadow={4}
            backgroundColor='#d8e2dc'
          >
            {picture && (
              <DraggableImage
                src={picture}
                isCentral={false}
                position={centralImagePosition}
                scale={centralImageScale}
              />
            )}
          </Box>

          {/* Left Div */}
          <Box
            position="absolute"
            top={`${sideSize * 10}px`}
            bottom={`${sideSize * 10}px`}
            left="-40px"
            width={`${sideSize * 30}px`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ boxSizing: 'border-box', border: '2px dotted rgb(13, 59, 102, .6)' }}
            boxShadow={4}
            backgroundColor='#d8e2dc'

          >
            {picture && (
              <DraggableImage
                src={picture}
                isCentral={false}
                position={centralImagePosition}
                scale={centralImageScale}
              />
            )}
          </Box>

          {/* Right Div */}
          <Box
            position="absolute"
            top={`${sideSize * 10}px`}
            bottom={`${sideSize * 10}px`}
            right="-40px"
            width={`${sideSize * 30}px`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ boxSizing: 'border-box', border: '2px dotted rgb(13, 59, 102, .6)' }}
            boxShadow={4}
            backgroundColor='#d8e2dc'
          >
            {picture && (
              <DraggableImage
                src={picture}
                isCentral={false}
                position={centralImagePosition}
                scale={centralImageScale}
              />
            )}
          </Box>

          {/* Top Div Height Marker */}
          <Box
            position="absolute"
            top={`${sideSize * 10}px`}
            bottom={`${sideSize * 10}px`}
            right="-90px"
            width={'30px'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              boxSizing: 'border-box',
              borderTop: '2px solid rgb(13, 59, 102, .3)',
              borderRight: '2px solid rgb(13, 59, 102, .3)',
              borderBottom: '2px solid rgb(13, 59, 102, .3)'
            }}
          >
            <Typography
              backgroundColor='rgb(238, 150, 75)'
              width={'100px'}
              position={'absolute'}
              mr={-4}
              textAlign={'center'}
              borderRadius={50}
              color={'white'}
              style={{ rotate: '90deg' }}
            >{height} inches</Typography>
          </Box>

        </Box>
      </Grid>
    </Grid>
  );
};

export default Canvas;
