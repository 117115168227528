import React, { useEffect, useRef } from 'react';
import './SocialMediaIcons.css';

const socialMediaLinks = [
  { name: 'YouTube', url: 'https://www.youtube.com', icon: 'fab fa-youtube' },
  { name: 'Facebook', url: 'https://www.facebook.com', icon: 'fab fa-facebook-f' },
  { name: 'Instagram', url: 'https://www.instagram.com', icon: 'fab fa-instagram' },
  { name: 'X', url: 'https://www.x.com', icon: 'fa-brands fa-x-twitter' }, // Twitter is now X
  { name: 'LinkedIn', url: 'https://www.linkedin.com', icon: 'fab fa-linkedin-in' },
  { name: 'Pinterest', url: 'https://www.pinterest.com', icon: 'fab fa-pinterest' },
  { name: 'Reddit', url: 'https://www.reddit.com', icon: 'fab fa-reddit-alien' },
];

const SocialMediaIcons = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, { threshold: 0.1 });
    const icons = container.querySelectorAll('.social-icon');

    icons.forEach(icon => observer.observe(icon));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="social-media-container" ref={containerRef}>
      {socialMediaLinks.map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <i className={link.icon}></i>
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
