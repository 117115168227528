import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const DraggableImage = ({ src, isCentral, position, scale, onDrag, onZoom, showZoomControls }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  const [startImagePosition, setStartImagePosition] = useState({ x: 0, y: 0 });
  const [currentScale, setCurrentScale] = useState(1);

  const containerRef = useRef(null);

  useEffect(() => {
    if (!isCentral && position) {
      setCurrentPosition(position);
    }
  }, [position, isCentral]);

  useEffect(() => {
    if (!isCentral && scale) {
      setCurrentScale(scale);
    }
  }, [scale, isCentral]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartDragPosition({ x: e.clientX, y: e.clientY });
    setStartImagePosition(currentPosition);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const dx = e.clientX - startDragPosition.x;
      const dy = e.clientY - startDragPosition.y;
      const newPosition = {
        x: startImagePosition.x + dx,
        y: startImagePosition.y + dy,
      };
      setCurrentPosition(newPosition);
      if (onDrag) {
        onDrag(newPosition);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleZoomIn = () => {
    const newScale = Math.min(currentScale + 0.2, 5);
    setCurrentScale(newScale);
    if (onZoom) {
      onZoom(newScale);
    }
  };

  const handleZoomOut = () => {
    const newScale = Math.max(currentScale - 0.5, 1);
    setCurrentScale(newScale);
    if (onZoom) {
      onZoom(newScale);
    }
  };

  const calculateBoundaries = () => {
    if (!containerRef.current) return currentPosition;

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;
    const imgWidth = containerWidth * currentScale;
    const imgHeight = containerHeight * currentScale;
    const maxX = (imgWidth - containerWidth) / 2;
    const maxY = (imgHeight - containerHeight) / 2;

    return {
      x: Math.min(Math.max(currentPosition.x, -maxX), maxX),
      y: Math.min(Math.max(currentPosition.y, -maxY), maxY),
    };
  };

  const boundedPosition = calculateBoundaries();

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <img
        src={src}
        alt="Draggable"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          cursor: isDragging ? 'grabbing' : 'grab',
          transform: `translate(${boundedPosition.x}px, ${boundedPosition.y}px) scale(${currentScale})`,
          transformOrigin: 'center',
          transition: isDragging ? 'none' : 'transform 0.3s ease',
        }}
        onMouseDown={handleMouseDown}
        onDragStart={(e) => e.preventDefault()}
      />
      {isCentral && showZoomControls && (
        <>
          <IconButton
            onClick={handleZoomIn}
            sx={{
              position: 'absolute',
              bottom: 40,
              right: 100,
              backgroundColor: 'rgba(255,255,255,0.7)',
            }}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            onClick={handleZoomOut}
            sx={{
              position: 'absolute',
              bottom: 40,
              right: 50,
              backgroundColor: 'rgba(255,255,255,0.7)',
            }}
          >
            <ZoomOutIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default DraggableImage;
