import React from 'react';
import './TermsAndConditions.css'; // Assuming you have a CSS file for styles

const ReturnRefundCancellationPolicy = () => {
    return (
        <div className="terms-and-conditions">
            <h1>Returns, Refund & Cancellation Policy</h1>

            <section>
                <h2>Interpretation</h2>
                <p>
                    In this policy, words with capitalized first letters hold specific meanings. These definitions apply regardless of whether the terms are used in singular or plural forms.
                </p>

                <h2>Definitions</h2>
                <p>
                    This policy covers the following terms:
                </p>
                <ul>
                    <li><strong>The Company:</strong> Refers to Bindu Creative, located at <strong>Jagamara, Bhubaneswar, Odisha 751030</strong>. Also referred to as "We", "Us", or "Our".</li>
                    <li><strong>Goods:</strong> Items available for purchase on our Service.</li>
                    <li><strong>Orders:</strong> Requests made by you to buy Goods from Us.</li>
                    <li><strong>Service:</strong> Refers to our website, Magicwall, available at <strong>artwalle.com</strong>.</li>
                    <li><strong>You:</strong> The individual or entity accessing or using the Service.</li>
                </ul>

                <h2>Cancellation Policies</h2>
                <ul>
                    <li>Full refunds are available if you cancel your order before the product is manufactured.</li>
                    <li>No cancellations are allowed once you approve the design proof provided by our design team.</li>
                    <li>Cancellations are not allowed for sample orders or installation kit orders.</li>
                    <li>All cancellations are subject to our return policies outlined below.</li>
                    <li>To cancel, you must clearly inform us of your decision by:
                        <ul>
                            <li>
                                Email: <strong><a href="mailto:support@artwalle.com">support@magicdecor.com</a></strong>
                            </li>
                            <li>
                                Phone Number:<strong> <a href="tel:+91-xxxxx xxxxx">+91-xxxxx xxxxx</a></strong>
                            </li>
                        </ul>
                    </li>
                </ul>

                <h2>Return Policy</h2>
                <ul>
                    <li>You are responsible for the cost and risk of returning Goods to Us.</li>
                    <li>Send returns to: <strong> Jagamara, Bhubaneswar, Odisha 751024</strong>.</li>
                    <li>We are not liable for damage or loss during return shipment; use an insured and trackable mail service.</li>
                    <li>Refunds are issued only after we receive the Goods or proof of return delivery.</li>
                    <li><strong>Conditions for returnable Goods:</strong>
                        <ul>
                            <li>Delivered within the last 7 days.</li>
                            <li>In original packaging.</li>
                            <li>Damaged during transit.</li>
                        </ul>
                    </li>
                </ul>
                <p><strong>Non-returnable items include:</strong></p>
                <ul>
                    <li>Customized Goods or those made to your specifications.</li>
                    <li>Goods that are perishable or past their expiry date.</li>
                    <li>Unsealed items for health or hygiene reasons.</li>
                    <li>Items mixed with other goods in such a way that they cannot be separated.</li>
                    <li>Customized wallpapers with customer-approved digital proofs.</li>
                </ul>

                <h2>Refund Policies</h2>
                <ul>
                    <li>Full refunds are processed within 14-21 days of receiving the returned product.</li>
                    <li>No transaction fees; refunds are issued via the original payment method.</li>
                    <li>Please verify the product meets your expectations before installation; installation and associated costs are not reimbursed.</li>
                </ul>

                <h2>Dedicated Design Assistance</h2>
                <ul>
                    <li>Premium wallpaper orders at Magicdecor.in include a dedicated designer for customization based on your wall measurements.</li>
                    <li>Design time is proportional to the wallpaper area and is calculated and added to your cart at checkout.</li>
                    <li>For cancellations, additional design or customization charges are non-refundable.</li>
                    <li>Exceeding allotted design time will result in deductions from your eligible refund.</li>
                    <li>This policy supersedes the 100% refund policy.</li>
                </ul>
            </section>

        </div>
    );
};

export default ReturnRefundCancellationPolicy;
