// src/SubLandingPage.js
import React, { useEffect } from 'react';
import './SubLandingPage.css';
import image1 from './Image1.jpg'
import image2 from './Image2.jpg'
import image3 from './Image3.jpg'
import image4 from './Image4.jpg'
import image5 from './Image5.jpg'

const SubLandingPage = () => {
    useEffect(() => {
        const elements = document.querySelectorAll('.fade-in');
        elements.forEach(element => {
            element.classList.add('visible');
        });
    }, []);

    return (
        <div className="sub-landing-page">
            <div className="content fade-in">
                <h1>wallpapers exactly as per your need</h1>
                <p>select your favorites one now.</p>
            </div>
            <div className="image-grid">
                <div className="image-item large fade-in">
                    <img src={image1} alt="Fern future home vibes" />
                    <p>Fern future home vibes</p>
                </div>
                <div className="image-item small fade-in">
                    <img src={image2} alt="My Scandinavian bedroom" />
                    <p>My Scandinavian bedroom</p>
                </div>
                <div className="image-item small fade-in">
                    <img src={image3} alt="The deck of my dreams" />
                    <p>The deck of my dreams</p>
                </div>
                <div className="image-item small fade-in">
                    <img src={image4} alt="Serve my drinks in style" />
                    <p>Serve my drinks in style</p>
                </div>
                <div className="image-item small fade-in">
                    <img src={image5} alt="Our bathroom upgrade" />
                    <p>Our bathroom upgrade</p>
                </div>
            </div>
        </div>
    );
};

export default SubLandingPage;
