import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css'; // Create a CSS file for styling
import SocialMediaIcons from '../SocialMedia/SocialMediaIcons';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    const wallpaperCategories = [
        "3D Wallpaper", "Kids Room", "Aesthetic", "Art & Paintings", "Tropical", "Vastu", "World Map", "Indian & Traditional",
        "Nature", "Abstract", "Animals", "Bamboo", "Beauty, Spa & Salon", "Birch Tree", "Bohemian", "Botanical", "Brick",
        "Cartoon", "Ceiling", "City Skyline", "Damask Pattern", "Floral", "Forest", "Galaxy", "Geometric", "Hotel", "Leaf & Creepers",
        "Music", "Patterns", "Religion & Culture", "Restaurant, Cafe & Lounge", "Shops & Establishments", "Office Wallpaper", "Space",
        "Stone Effects", "Stripes", "Surfaces", "Tribal", "Vintage", "Wooden", "Waterfall", "Deewar Wallpaper", "Peacock Wallpaper",
        "Rajasthani Wallpaper", "Romantic", "3d Wallpaper for Bedroom Walls", "Ganesha Wallpaper", "Buddha", "Krishna", "Bedroom",
        "Living Room", "Kitchen", "Pooja Room", "Gaming Room", "Dining Room", "Guest Room", "Floor Wallpaper", "Furniture Wallpaper",
        "Personalities", "Educational", "Fitness, Gym & Yoga"
    ];

    const importantLinks = [
        {
            title: 'Designs',
            links: [
                { name: 'Browse Designs', path: '/browse-designs' },
                { name: 'Design your own', path: '/design-your-own' },
                { name: 'Order sample', path: '/order-sample' },
                { name: 'Design Collections', path: '/design-collections' },
                { name: 'Wallpaper Calculator', path: '/wallpaper-calculator' },
            ],
        },
        {
            title: 'Information',
            links: [
                { name: 'How to Measure Walls?', path: '/how-to-measure-walls' },
                { name: 'How to order?', path: '/how-to-order' },
                { name: 'How to install?', path: '/how-to-install' },
                { name: 'FAQs', path: '/faqs' },
            ],
        },
        {
            title: 'Account',
            links: [
                { name: 'Orders', path: '/orders' },
                { name: 'Coupons', path: '/coupons' },
                { name: 'Design Collections', path: '/design-collections' },
                { name: 'Addresses', path: '/addresses' },
            ],
        },
        {
            title: 'Policy',
            links: [
                { name: 'Terms and Conditions', path: '/TermsAndConditions' },
                { name: 'Returns, Refund & Cancellation', path: '/ReturnRefundCancellationPolicy' },
                { name: 'Payments & Shipping', path: '/PaymentAndShippment' },
            ],
        },
    ];



    return (
        <div className="footer">

            {/* Term and Conditions */}
            <div className="footer-item"></div>

            <div className="footer-container">

                {/* Company Info */}
                <div className="footer-company-info">
                    <h2>Artwalle</h2>
                    <h5>Where Creativity Meets Home Decor</h5>
                    <p>
                        Since its inception in 2019, Artwalley has been empowering customers to boldly
                        showcase their individuality. Our tailor-made wallpapers are meticulously
                        designed to cater to the distinct preferences of each client, delivering
                        an enchanting and durable solution that promises a lifetime of enjoyment.
                    </p>
                </div>

                {/* Social Media */}
                <div className="footer-social-media">
                    <SocialMediaIcons />

                    {/* Important Links */}
                    <div className="footer-links">
                        {importantLinks.map((section, index) => (
                            <ul key={index}>
                                <li><strong>{section.title}</strong></li>
                                {section.links.map((link, idx) => (
                                    <li key={idx}>
                                        <button onClick={() => handleNavigation(link.path)}>{link.name}</button>
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>

                </div>

                {/* Contact Us */}
                <div className="footer-contact">
                    <h4>Contact Us</h4>
                    <div className="footer-contact-item">
                        <span className="icon"><i class="fa-solid fa-location-dot"></i></span>
                        <p>
                            Plot No - 584, Jagamara, Khandagiri, <br />
                            City: Bhubaneswar, State: Khordha,<br />
                            State: Odisha, Pin: 751030
                        </p>
                    </div>
                    <div className="footer-contact-item">
                        <span className="icon"><i class="fa-solid fa-phone"></i></span>
                        <p>+91 70085 75985</p>
                    </div>
                    <div className="footer-contact-item">
                        <span className="icon"><i class="fa-solid fa-envelope"></i></span>
                        <p>contact@artwalley.com</p>
                    </div>
                    <div className="footer-contact-item">
                        <span className="icon"><i class="fa-solid fa-earth-americas"></i></span>
                        <p>artWalley.com</p>
                    </div>
                </div>

            </div>



            {/* Shipping Notification */}
            <div className="footer-shipping">
                <p>
                    ** FREE Shipping available for all cities in India.
                    Priority shipping can be availed at Rs. 699. Installation services
                    are limited to major metro cities in India. For installation feasibility
                    and charges please contact our sales team or check feasibility on the
                    checkout page. FREE installation is limited to certain cities.
                </p>
            </div>

            {/* Links */}
            <div className="footer-categories">
                {wallpaperCategories.map((category, index) => (
                    <button
                        key={index}
                        onClick={() => handleNavigation(`/products/${category.toLowerCase().replace(/ /g, '-')}`)}
                        className="footer-category"
                    >
                        {category}
                    </button>
                ))}
            </div>

            {/* Developer Details */}
            <div className="footer-developer">
                <div className="footer-developer-container">
                    <p>all content copyrights reserved by Artwalle</p>
                    <p>Designed and Developed by PDP (pattanaikdatapoint.com)</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
