import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Avatar, Typography, Rating, IconButton } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

const CustomerReview = () => {
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({
    customerName: '',
    rating: 0,
    comment: '',
    reviewDate: '',
    productName: '',
    productImage: null,
  });

  useEffect(() => {
    axios.get('https://api.artwalle.pattanaikdatapoint.com/CustomerReview')
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the reviews!', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prev) => ({ ...prev, [name]: value }));
  };

  const handleRatingChange = (event, newValue) => {
    setNewReview((prev) => ({ ...prev, rating: newValue }));
  };

  const handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewReview((prev) => ({
        ...prev,
        productImage: e.target.files[0],
      }));
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('customerName', newReview.customerName);
    formData.append('rating', newReview.rating);
    formData.append('comment', newReview.comment);
    formData.append('reviewDate', newReview.reviewDate);
    if (newReview.productName) formData.append('productName', newReview.productName);
    if (newReview.productImage) formData.append('productImage', newReview.productImage);

    axios.post('https://api.artwalle.pattanaikdatapoint.com/CustomerReview', formData)
      .then((response) => {
        setReviews((prev) => [...prev, response.data]);
        setNewReview({ customerName: '', rating: 0, comment: '', reviewDate: '', productName: '', productImage: null });
      })
      .catch((error) => {
        console.error('There was an error submitting the review!', error);
      });
  };

  return (
    <Box>
      <Typography variant="h5">Customer Reviews</Typography>
      {reviews.map((review, index) => (
        <Box key={index} display="flex" alignItems="center" mb={2}>
          <Avatar src={review.productImage} alt={`Avatar ${index}`} />
          <Box ml={2}>
            <Rating value={review.rating} readOnly />
            <Typography variant="body1">{review.comment}</Typography>
            <Typography variant="body2">{review.customerName}</Typography>
            <Typography variant="body2">{new Date(review.reviewDate).toLocaleDateString()}</Typography>
            {review.productName && <Typography variant="body2">Product: {review.productName}</Typography>}
          </Box>
        </Box>
      ))}
      <Box mt={4} component="form" display="flex" flexDirection="column">
        <Typography variant="h6">Add a Review</Typography>
        <TextField
          name="customerName"
          label="Name"
          value={newReview.customerName}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          name="reviewDate"
          label="Date"
          type="date"
          value={newReview.reviewDate}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Rating
          name="rating"
          value={newReview.rating}
          onChange={handleRatingChange}
          mt={2}
        />
        <TextField
          name="comment"
          label="Comment"
          multiline
          rows={4}
          value={newReview.comment}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          name="productName"
          label="Product Name"
          value={newReview.productName}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <Box display="flex" alignItems="center" mt={2}>
          <Avatar src={newReview.productImage ? URL.createObjectURL(newReview.productImage) : ''} alt="Product" />
          <label htmlFor="icon-button-file">
            <Input accept="image/*" id="icon-button-file" type="file" onChange={handleAvatarChange} />
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
            </IconButton>
          </label>
        </Box>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Review
        </Button>
      </Box>
    </Box>
  );
};

export default CustomerReview;
