import React from 'react';
import { Button } from '@mui/material';

const PictureUploader = ({ setPicture }) => {
  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-button"
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor="upload-button">
        <Button variant="contained" component="span">
          Upload Image
        </Button>
      </label>
    </div>
  );
};

export default PictureUploader;
