import React from 'react';
import './ContactBar.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const ContactBar = () => {
    return (
        <div className="contact-bar">
            <div className="contact-item">
                <a href="https://wa.me/+917008575985" target="_blank" rel="noopener noreferrer" className="contact-link">
                    <WhatsAppIcon className="contact-icon" />
                    <span className="contact-text">Whatsaap Chat</span>
                </a>
            </div>
            <div className="contact-item">
                <a href="tel:+917008575985" className="contact-link">
                    <LocalPhoneIcon className="contact-icon" />
                    <span className="contact-text">+91 70085 75985</span>
                </a>
            </div>
        </div>
    );
};

export default ContactBar;
